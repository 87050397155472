var template = require('~/js/template/content/nested/house/projectTemplate.html'),
	App = require('~/js/App'),
    data = require('~/js/Data');

module.exports = Backbone.View.extend({
       
    id: 'project',

    events: {
        "click":  "onButtonClick"
    },      

    initialize: function( id ) {
        this.houseId = id;
        this.render();
    },

    render: function() {
        this.el.innerHTML = template( {} );        
        return this;
    },

    update: function( id ) {
        this.houseId = id;
    },

    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }, 
    onButtonClick: function( event ) {
        var obj = data.house[ this.houseId ]( document.getElementById( this.houseId ) );
        if ( App.investor ) obj.investor = App.investor;
        if ( App.misto ) obj.misto = App.misto;        
        if ( App.datum ) obj.datum = App.datum;
        obj.focusdatum = new Date().toLocaleDateString();
        obj.id = this.houseId;
        //
        App.popUp.show( 'projectTemplate', obj,
        ( popupEl ) => {
            if ( popupEl ) {
                App.loader.show();
                //
                var investor = popupEl.querySelector( 'input#investor' ).value;
                var misto = popupEl.querySelector( 'input#misto' ).value;
                var datum = popupEl.querySelector( 'input#datum' ).value;
                //
                if ( investor ) App.investor = obj.investor = investor;
                if ( misto ) App.misto = obj.misto = misto;
                if ( datum ) App.datum = obj.datum = datum;

                var xhr = new XMLHttpRequest();
                xhr.open('POST', App.API + '/pdf', true);
                xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                xhr.responseType = 'blob';
                xhr.onreadystatechange = function() {
                    var a;
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        // Trick for making downloadable link
                        a = document.createElement('a');
                        a.href = window.URL.createObjectURL(xhr.response);
                        // Give filename you wish to download
                        a.download = obj.investor + "_" + obj.misto + "_" + obj.datum + ".pdf";
                        a.style.display = 'none';
                        document.body.appendChild(a);
                        a.click();
                        App.loader.hide();
                    }
                }; 
         
                App.pc.takeScreens( this.houseId, ( images ) => {
                    xhr.send( JSON.stringify( { text: obj, images: images } ) );
                });

                //debug temp
                //App.pc.takeScreen( this.houseId, ( images ) => {
                //});

            }
        });
    }
});