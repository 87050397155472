var template = require('~/js/template/content/nested/userTemplate.html'),
    App = require("~/js/App"),
    Utils = require('~/js/Utils');

module.exports = Backbone.View.extend({
       
    tagName: 'div',

    className: 'user',

    events: {
        "click a":  "onLogoutClick"
    },    

    initialize: function() {
        this.render();
    },

    render: function() {
        this.el.innerHTML = template();        
        return this;
    },

    onLogoutClick: function( evt ) {
        evt.preventDefault();
        //
        App.user.logout();
    },   

    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }   

});