import protoScript from '~/js/lib/ProtoScript';
import App from '~/js/App';
import Utils from '~/js/Utils';

//var FlowInfo = require('./FlowInfo').default;

var LoadModel = function(){
    this.name = 'loadModel';
    //
    this.attributes = {
        'scale': {  type: " Number", default:"1.0"}
    };
};

LoadModel.prototype.initialize = function() {
    this.currFile = '';
    this.fadeMaterialsList = [];
    //
    this.on('enable', this.onEnable, this);
    this.on('disable', this.onDisable, this);  
    this.on('destroy', this.onDestroy, this);  
    //
    this.onEnable();
};
LoadModel.prototype.update = function( dt ) {};

//handlers
LoadModel.prototype.onEnable = function() {
  
};
LoadModel.prototype.onDisable = function() {

};

LoadModel.prototype.onDestroy = function () {
    this.off('enable', this.onEnable, this);
    this.off('disable', this.onDisable, this);  
    this.off('destroy', this.onDestroy, this);    
    //
    this.disposeModel();
};
//
LoadModel.prototype.load = function( file ) {
    //
    if ( file != this.currFile ) {
        if ( this.gltf )
            this.disposeModel();
        //
        this.currFile = file;
        console.log('nahravam@@', file  );  
        App.loader.show();       
        this.app.assets.loadFromUrl( App.ASSETS + 'models/' + file  + '.glb', "container", (err, asset) => {
            App.loader.hide();            
            console.log('nahrano@@', file  ); 
            this.asset = asset;
            this.addLoadedModel(err, asset.resource);
        });
    }
};

LoadModel.prototype.addLoadedModel = function(err, res) {
    this.fades = [];
    this.states = [];
    this.tags = [];    
    this.grabExtras( res.model.resources[0].graph );

    //console.log('this.fades', this.fades );
    //console.log('this.states', this.states );
    //
    var screenLayer = this.app.scene.layers.getLayerByName( 'ScreenShotLayer' );
    var worldLayer = this.app.scene.layers.getLayerById( pc.LAYERID_WORLD );

    this.gltf = new pc.Entity('gltf');
    this.gltf.addComponent('model', {
        type: "asset",
        asset: res.model,
        castShadows: true,
        layers: []
    });

    //filter meshinstances which does not have layer tag
    var miFiltered = this.gltf.model.model.meshInstances.filter(( mi ) => {
        return Object.keys( this.tags ).filter(key => this.tags[key].hasOwnProperty('layer')).map(tag => { return mi.node.name !== tag; }).every(( tag ) => { return tag; });
    });

    this.miFilteredWorld = this.gltf.model.model.meshInstances.filter(( mi ) => {
        return Object.keys( this.tags ).filter(key => this.tags[key].layer === 'world').map(tag => { return mi.node.name === tag; }).some(( tag ) => { return tag; });
    });
    this.miFilteredScreen = this.gltf.model.model.meshInstances.filter(( mi ) => {
        return Object.keys( this.tags ).filter(key => this.tags[key].layer === 'ScreenShotLayer').map(tag => { return mi.node.name === tag; }).some(( tag ) => { return tag; });
    });

    //okna a dvere 0np
    this.miFilteredWin0np = this.gltf.model.model.meshInstances.filter(( mi ) => {
        return Object.keys( this.tags ).filter(key => this.tags[key].layer === 'win0np').map(tag => { return mi.node.name === tag; }).some(( tag ) => { return tag; });
    });
    //okna a dvere 1np
    this.miFilteredWin1np = this.gltf.model.model.meshInstances.filter(( mi ) => {
        return Object.keys( this.tags ).filter(key => this.tags[key].layer === 'win1np').map(tag => { return mi.node.name === tag; }).some(( tag ) => { return tag; });
    });    
    //koty
    this.miFilteredGraphics = this.gltf.model.model.meshInstances.filter(( mi ) => {
        return Object.keys( this.tags ).filter(key => this.tags[key].layer === 'ScreenShotLayer').map(tag => { return mi.node.name === tag; }).some(( tag ) => { return tag; });
    });        

    //turn off cast shadow for screenshot layer
    this.miFilteredScreen.forEach( ( mi ) => { mi.castShadow = false; });

    this.miFilteredWorld = [ ...this.miFilteredWorld, ...miFiltered, ...this.miFilteredWin0np, ...this.miFilteredWin1np ];
    this.miFilteredScreen = [ ...this.miFilteredScreen, ...miFiltered, ...this.miFilteredWin0np, ...this.miFilteredWin1np ];

    worldLayer.addMeshInstances( this.miFilteredWorld );
    screenLayer.addMeshInstances( this.miFilteredScreen );

    //
    this.entity.script.fadeByStyle.setData( this.fades, this.gltf.model.model );    
    this.entity.script.morphByStyle.setData( this.states, this.gltf.model.model );
    this.entity.script.transByStyle.setData( this.states, this.gltf.model.model );
    this.entity.script.tintByStyle.setDefaultTint( this.gltf.model.model );    
    //
    this.entity.addChild( this.gltf );

    //this.entity.setLocalScale( this.scale, this.scale, this.scale );

    //store dimensions of model which I calculate from aabb accross meshinstances
    //this.entity.dimensions = this.getModelDimensions( res.model.resources[0] );
    //this.entity.script.elementToEntity.addElement( this.entity );
    //
    // create animations
    if (res.animations && res.animations.length > 0) {
        this.gltf.addComponent('animation', {
            assets: res.animations.map(function (asset) {
                return asset.id;
            }),
            speed: 1,
            //activate: false //dont play onload
        });

        var animationMap = {};
        for (var i = 0; i < res.animations.length; ++i) {
            var animAsset = res.animations[i];
            animationMap[animAsset.resource.name] = animAsset.name;
        }

        this.animationMap = animationMap;
    }
};

LoadModel.prototype.addToScreenLayer = function( name ) {
    var screenLayer = this.app.scene.layers.getLayerByName( 'ScreenShotLayer' );
    //
    screenLayer.clearMeshInstances();
    screenLayer.addMeshInstances( this[name] );
    //
    return new Promise( (resolve ) => setTimeout(resolve,100) );
};

LoadModel.prototype.layerAdd = function() {
    var worldLayer = this.app.scene.layers.getLayerById( pc.LAYERID_WORLD );
    var screenLayer = this.app.scene.layers.getLayerByName( 'ScreenShotLayer' );   
    //
    worldLayer.addMeshInstances( this.miFilteredWorld );
    screenLayer.addMeshInstances( this.miFilteredScreen );
};

LoadModel.prototype.layerClear = function() {
    var worldLayer = this.app.scene.layers.getLayerById( pc.LAYERID_WORLD );
    var screenLayer = this.app.scene.layers.getLayerByName( 'ScreenShotLayer' ); 
    //
    worldLayer.removeMeshInstances( this.miFilteredWorld );
    screenLayer.removeMeshInstances( this.miFilteredScreen );    
};

LoadModel.prototype.disposeModel = function () {
    console.log('dispose');
    this.entity.removeChild(this.gltf);

    // First destroy the glTF entity...
    if (this.gltf) {
        if (this.gltf.animComponent) {
            this.gltf.animComponent.stopClip();
        }
        this.gltf.destroy();
        this.gltf = null;
    }
    if (this.asset) {
        this.app.assets.remove(this.asset);
        this.asset.unload();
        this.asset = null;
    }
    // Blow away all properties holding the loaded scene
    delete this.asset;
    delete this.animationClips;
    delete this.gltf;
};

//animations
LoadModel.prototype.switchToClipByName = function(clipName) {
    if (this.gltf && this.gltf.animation) {
        this.gltf.animation.loop = false;
        this.gltf.animation.enabled = true;
        if (clipName) {
            this.gltf.animation.play(this.animationMap[clipName], 0); //0 =blend time
        } else {
            this.gltf.animation.playing = true;
        }
    }
};

//tools
LoadModel.prototype.grabExtras = function( modelGraph, test ) {
    if (modelGraph === undefined )
        return;

    modelGraph.children.forEach( ( child ) => {
        if ( child.hasOwnProperty('extras')) {
            if ( child.extras.hasOwnProperty('fade') ) {
                this.fades[ child.name ] = Utils.tryParseJSON( child.extras.fade );
                if ( child.children )
                    this.fades[ child.name ].children = child.children;
            }

            if ( child.extras.hasOwnProperty('stateGroups') )
                this.states[ child.name ] = Utils.tryParseJSON( child.extras.stateGroups );

            if ( child.extras.hasOwnProperty('tags') )
                this.tags[ child.name ] = this.tagToObject( child.extras.tags );
        }
        this.grabExtras( child, true );
    });
};

LoadModel.prototype.tagToObject = function( tags ) {
    var obj = {};
    //
    tags.split(',').forEach( ( tag ) => {
        var part = tag.split('-');
        if ( part.length !== 2 ) return;
        obj[ part[0] ] = part[1];
    });
    //
    return obj;
};

//
export default protoScript( LoadModel );
