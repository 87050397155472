var Utils = require('~/js/Utils');
module.exports = {

    house:{
    	'classic': ( element ) => { return {
            file: 'Classic.png',
            url: 'classic',
            title_short: 'CLASSIC',
            title_long: 'rodinný dům Classic',
            subtitle: Utils.hasClass( element, 'orientace-zakladni' ) ? 'základní provedení' : 'zrcadlové provedení',
            investor: '',
            misto: '',
            datum: '',
            imgtoonepage: true,
            floorImageWidth: Utils.hasClass( element, 'auto-stani' ) || Utils.hasClass( element, 'auto-garaz' ) ? 450 : 512,
            table: [{
                headers: ['', '', ''],
                rows: [
                    ['OBÝVACÍ POKOJ',28.60,'plovoucí podlaha'],
                    ['KUCHYŇ',11.50,'dlažba'],
                    ['PRACOVNA',11.50,'koberec'],
                    ['HOBBY + KOUPELNA',10.70,'dlažba'],
                    ['ZÁDVEŘÍ + CHODBA',10.00,'dlažba'],
                    ['KOMORA',2.70,'dlažba'],
                    ...(Utils.hasClass( element, 'auto-garaz' ) ? [['GARÁŽ',17.40,'betonová mazanina']] : []),
                    ['Celkový součet:', '75.00', '']
                ] 
            },{
                headers: ['', '', ''],
                rows: [
                    ['GALERIE, SCHODIŠTĚ',11.90,'dlažba'],
                    ['KOUPELNA',9.10,'dlažba'],
                    ['LOŽNICE',20.10,'koberec'],
                    ['POKOJ',20.10,'koberec'],
                    ['POKOJ',11.50,'koberec'],
                    ...(Utils.hasClass( element, 'samostatnewc1np-ne' ) ? [['ŠATNA',4.00,'koberec']] : []),
                    ...(Utils.hasClass( element, 'samostatnewc1np-ano' ) ? [['WC',2.70,'dlažba']] : []),
                    ['Celkový součet:', '76.70', '']
                ]
            }], 
            tech_info: "Předmětem návrhu je rodinný dům typ CLASSIC. Jedná se o izolovaný, nepodsklepený, jednopodlažní objekt s obytným podkrovím.\
 V přízemí objektu se nachází kuchyň/kuchyňský kout, pracovna, obývací pokoj, WC/koupelna, komora, hobby a hala se schodištěm. V podkroví jsou pokoje, ložnice,\
 " + (Utils.hasClass( element, 'samostatnewc1np-ano' ) ? "WC," : "šatna,") + " galerie a koupelna.",
            tech_info_roof: "Vrchol hřebene střechy – výšková kóta +8,300m (vytaženo k +0,000=PODLAHA 1.NP).",
            tech_info_list_title: "Konstrukce objektu je z velkoplošných dílců ECOBETON Canaba® tl. 150mm. Skladba konstrukce od vnější strany:",
            tech_info_list: ["stěrková strukturovaná omítka", "disperzní nátěr", "stěrkový tmel + výztužná tkanina VT1", "pěnový polystyren EPS 70 F tl. 200 mm na talířové hmoždinky", "lepící tmel", "dílec ECOBETON Canaba®", "Vnitřní nosné stěny jsou z dílců ECOBETON Canaba® typové tl. 150mm."],
            tech_info_etc1: "Stropní konstrukce je ze stropních dílců ECOBETON Canaba®. Stropní dílce jsou v tl. 150mm, podestový tl. 200mm. Ztužení objektu je provedeno betonovou zálivkou a zálivkovou výztuží.\n\nPodlahy v 1. NP  RD jsou betonové, s povrchovou úpravou dle tabulky podlah, ve 2.NP jsou podlahy nulové s nášlapnou vrstvou dle tabulky.\n\nObjekt je zastřešen sedlovou střechou se sklonem 45°. Krov je celodřevěný hambalkové soustavy s laťováním.\n\nVnitřní stěny objektu jsou opatřeny štukovou stěrkou na betonový povrch. Vnější povrch fasády tvoří strukturovaná omítka.",
            tech_info_etc2: "Dveře vnitřní jsou použity plné do obložkových zárubní. Vchodové, balkonové a lodžiové dveře budou typické materiál viz okna.\n\nIzolace tepelné v podlaze 1.NP jsou součástí dodávky spodní stavby a budou provedeny grafitovými deskami EPS 150 tl. 100mm (podlahy). Izolace tepelné v podkroví budou provedeny rohožemi z minerální vlny tl.290 mm. Izolace obvodových stěn je z EPS 70 F tl. 200 mm.\n\nStřecha bude provedena z betonových tašek, na dřevěné laťování. Oplechování střechy a parapetů, střešní svody, kotlíky a okapní žlaby jsou z titanzinkového plechu.\n\nMalby vnitřní budou disperzní bílé.\n\nDlažby budou provedeny keramické, dle výpisu podlah. Obklady budou keramické - na WC a v koupelně do výšky 2 m."
        };},
        'classiccity': ( element ) => { return {
            file: 'ClassicCity.png',
            url: 'classiccity',
            title_short: 'CLASSIC CITY',
            title_long: 'rodinný dům Classic City',
            subtitle: Utils.hasClass( element, 'orientace-zakladni' ) ? 'základní provedení' : 'zrcadlové provedení',
            investor: '',
            misto: '',
            datum: '',
            imgtoonepage: true,
            floorImageWidth: Utils.hasClass( element, 'auto-stani' ) || Utils.hasClass( element, 'auto-garaz' ) ? 450 : 512,            
            table: [{
                headers: ['', '', ''],
                rows: [
                    ['OBÝVACÍ POKOJ',28.60,'plovoucí podlaha'],
                    ['KUCHYŇ',11.50,'dlažba'],
                    ['PRACOVNA',11.50,'koberec'],
                    ['HOBBY + KOUPELNA',10.70,'dlažba'],
                    ['ZÁDVEŘÍ + CHODBA',10.00,'dlažba'],
                    ['KOMORA',2.70,'dlažba'],
                    ...(Utils.hasClass( element, 'auto-garaz' ) ? [['GARÁŽ',17.40,'betonová mazanina']] : []),
                    ['Celkový součet:', '75.00', '']
                ] 
            },{
                headers: ['', '', ''],
                rows: [
                    ['GALERIE, SCHODIŠTĚ',11.90,'dlažba'],
                    ['KOUPELNA',11.50,'dlažba'],
                    ['LOŽNICE',20.10,'koberec'],
                    ['POKOJ',20.10,'koberec'],
                    ['POKOJ',11.50,'koberec'],
                    ...(Utils.hasClass( element, 'samostatnewc1np-ne' ) ? [['ŠATNA',4.00,'koberec']] : []),
                    ...(Utils.hasClass( element, 'samostatnewc1np-ano' ) ? [['WC',2.70,'dlažba']] : []),
                    ['Celkový součet:', '79.10', '']
                ]
            }], 
            tech_info: "Předmětem návrhu je rodinný dům typ CLASSIC CITY. Jedná se o izolovaný, nepodsklepený, jednopodlažní objekt s obytným podkrovím.\
 V přízemí objektu se nachází kuchyň/kuchyňský kout, pracovna, obývací pokoj, WC/koupelna, komora, hobby a hala se schodištěm. V podkroví jsou pokoje, ložnice,\
 " + (Utils.hasClass( element, 'samostatnewc1np-ano' ) ? "WC," : "šatna,") + " galerie a koupelna.",
            tech_info_roof: "Vrchol hřebene střechy-výšková kóta +6,458 m (vztaženo k +0,000 = PODLAHA 1.NP)",
            tech_info_list_title: "Konstrukce objektu je z velkoplošných dílců ECOBETON Canaba® tl. 150mm. Skladba konstrukce od vnější strany:",
            tech_info_list: ["stěrková strukturovaná omítka", "disperzní nátěr", "stěrkový tmel + výztužná tkanina VT1", "pěnový polystyren EPS 70 F tl. 200 mm na talířové hmoždinky", "lepící tmel", "dílec ECOBETON Canaba®", "Vnitřní nosné stěny jsou z dílců ECOBETON Canaba® typové tl. 150mm."],
            tech_info_etc1: "Stropní konstrukce je ze stropních dílců ECOBETON Canaba®. Stropní dílce jsou v tl. 150mm, podestový tl. 200mm. Ztužení objektu je provedeno betonovou zálivkou a zálivkovou výztuží.\n\nPodlahy v 1. NP  RD jsou betonové, s povrchovou úpravou dle tabulky podlah, ve 2.NP jsou podlahy nulové s nášlapnou vrstvou dle tabulky.\n\nObjekt je zastřešen sedlovou střechou se sklonem 14°. Krov je celodřevěný hambalkové soustavy s laťováním.\n\nVnitřní stěny objektu jsou opatřeny štukovou stěrkou na betonový povrch. Vnější povrch fasády tvoří strukturovaná omítka.",
            tech_info_etc2: "Dveře vnitřní jsou použity plné do obložkových zárubní. Vchodové, balkonové a lodžiové dveře budou typické materiál viz okna.\n\nIzolace tepelné v podlaze 1.NP jsou součástí dodávky spodní stavby a budou provedeny grafitovými deskami EPS 150 tl. 100mm (podlahy). Izolace tepelné v podkroví budou provedeny rohožemi z minerální vlny tl.290 mm. Izolace obvodových stěn je z EPS 70 F tl. 200 mm.\n\nStřecha bude provedena z betonových tašek, na dřevěné laťování. Oplechování střechy a parapetů, střešní svody, kotlíky a okapní žlaby jsou z titanzinkového plechu.\n\nMalby vnitřní budou disperzní bílé.\n\nDlažby budou provedeny keramické, dle výpisu podlah. Obklady budou keramické - na WC a v koupelně do výšky 2 m."
        };},
        'ideal': ( element ) => { return {
            file: 'Ideal.png',
            url: 'ideal',
            title_short: 'IDEAL',
            title_long: 'rodinný dům Ideal',
            subtitle: Utils.hasClass( element, 'orientace-zakladni' ) ? 'základní provedení' : 'zrcadlové provedení',
            investor: '',
            misto: '',
            datum: '',
            imgtoonepage: true,
            floorImageWidth: Utils.hasClass( element, 'auto-stani' ) || Utils.hasClass( element, 'auto-garaz' ) ? 450 : 512,    
            table: [{
                headers: ['', '', ''],
                rows: [
                    ...(Utils.hasClass( element, 'kuchynvobytnem-ne' ) && Utils.hasClass( element, 'pracovna-ne' ) ? [['OBÝVACÍ POKOJ',33.30,'plovoucí podlaha'],['KUCHYŇ',11.50,'dlažba']] : []),
                    ...(Utils.hasClass( element, 'kuchynvobytnem-ano' ) && Utils.hasClass( element, 'pracovna-ne' ) ? [['OBÝVACÍ POKOJ+KK',33.20,'plovoucí podlaha/dlažba'],['POKOJ',11.50,'koberec']] : []),
                    ...(Utils.hasClass( element, 'kuchynvobytnem-ne' ) && Utils.hasClass( element, 'pracovna-ano' ) ? [['OBÝVACÍ POKOJ',24.00,'plovoucí podlaha'],['KUCHYŇ',11.50,'dlažba'],['PRACOVNA',9.10,'koberec']] : []),
                    ...(Utils.hasClass( element, 'vstupzestitu-ne' ) ? [['HOBBY + KOUPELNA',8.50,'dlažba']] : []),   
                     ...(Utils.hasClass( element, 'vstupzestitu-ano' ) ? [['ZÁDVEŘÍ + KOUPELNA',8.50,'dlažba']] : []),                    
                    ['HALA',8.20,'dlažba'],                    
                    ['KOMORA',2.40,'dlažba'],
                    ...(Utils.hasClass( element, 'auto-garaz' ) ? [['GARÁŽ',17.40,'betonová mazanina']] : []),
                    ...(Utils.hasClass( element, 'auto-garaz' ) ? [['Celkový součet:', '81.30', '']] : [['Celkový součet:', '63.90', '']])    
                ] 
            },{
                headers: ['', '', ''],
                rows: [
                    ['GALERIE, SCHODIŠTĚ',6.50,'dlažba'],
                    ['KOUPELNA',7.00,'dlažba'],
                    ['LOŽNICE',15.20,'koberec'],
                    ['POKOJ',17.50,'koberec'],
                    ['PRACOVNA',11.50,'koberec'],
                    ['ŠATNA',3.70,'koberec'],
                    ['Celkový součet:', '61.40', '']
                ]
            }], 
            tech_info: "Předmětem návrhu je rodinný dům typ IDEAL. Jedná se o izolovaný, nepodsklepený, jednopodlažní objekt s obytným podkrovím.\
 V přízemí objektu se nachází kuchyň/kuchyňský kout,\
 " + (Utils.hasClass( element, 'kuchynvobytnem-ano' ) ? "pracovna, " : "") + "obývací pokoj, WC/koupelna, komora, hobby a hala se schodištěm. V podkroví jsou pokoje, ložnice,\
 " + (Utils.hasClass( element, 'samostatnewc1np-Ano' ) ? "WC," : "šatna,") + " galerie a koupelna.",
            tech_info_roof: "Vrchol hřebene střechy – výšková kóta +8,300m (vytaženo k +0,000=PODLAHA 1.NP).",
            tech_info_list_title: "Konstrukce objektu je z velkoplošných dílců ECOBETON Canaba® tl. 150mm. Skladba konstrukce od vnější strany:",
            tech_info_list: ["stěrková strukturovaná omítka", "disperzní nátěr", "stěrkový tmel + výztužná tkanina VT1", "pěnový polystyren EPS 70 F tl. 200 mm na talířové hmoždinky", "lepící tmel", "dílec ECOBETON Canaba®", "Vnitřní nosné stěny jsou z dílců ECOBETON Canaba® typové tl. 150mm."],
            tech_info_etc1: "Stropní konstrukce je ze stropních dílců ECOBETON Canaba®. Stropní dílce jsou v tl. 150mm, podestový tl. 200mm. Ztužení objektu je provedeno betonovou zálivkou a zálivkovou výztuží.\n\nPodlahy v 1. NP  RD jsou betonové, s povrchovou úpravou dle tabulky podlah, ve 2.NP jsou podlahy nulové s nášlapnou vrstvou dle tabulky.\n\nObjekt je zastřešen sedlovou střechou se sklonem 45°. Krov je celodřevěný hambalkové soustavy s laťováním.\n\nVnitřní stěny objektu jsou opatřeny štukovou stěrkou na betonový povrch. Vnější povrch fasády tvoří strukturovaná omítka.",
            tech_info_etc2: "Dveře vnitřní jsou použity plné do obložkových zárubní. Vchodové, balkonové a lodžiové dveře budou typické materiál viz okna.\n\nIzolace tepelné v podlaze 1.NP jsou součástí dodávky spodní stavby a budou provedeny grafitovými deskami EPS 150 tl. 100mm (podlahy). Izolace tepelné v podkroví budou provedeny rohožemi z minerální vlny tl.290 mm. Izolace obvodových stěn je z EPS 70 F tl. 200 mm.\n\nStřecha bude provedena z betonových tašek, na dřevěné laťování. Oplechování střechy a parapetů, střešní svody, kotlíky a okapní žlaby jsou z titanzinkového plechu.\n\nMalby vnitřní budou disperzní bílé.\n\nDlažby budou provedeny keramické, dle výpisu podlah. Obklady budou keramické - na WC a v koupelně do výšky 2 m."
        };},
        'idealcity': ( element ) => { return {
            file: 'IdealCity.png',
            url: 'idealcity',
            title_short: 'IDEAL CITY',
            title_long: 'rodinný dům Ideal City',
            subtitle: Utils.hasClass( element, 'orientace-zakladni' ) ? 'základní provedení' : 'zrcadlové provedení',
            investor: '',
            misto: '',
            datum: '',
            imgtoonepage: true,
            floorImageWidth: Utils.hasClass( element, 'auto-stani' ) || Utils.hasClass( element, 'auto-garaz' ) ? 450 : 512,    
            table: [{
                headers: ['', '', ''],
                rows: [
                    ...(Utils.hasClass( element, 'kuchynvobytnem-ne' ) ? [['OBÝVACÍ POKOJ',33.40,'plovoucí podlaha'],['KUCHYŇ',11.50,'dlažba']] : []),
                    ...(Utils.hasClass( element, 'kuchynvobytnem-ano' ) ? [['OBÝVACÍ POKOJ+KK',33.40,'plovoucí podlaha/dlažba'],['POKOJ',11.50,'koberec']] : []),
                    ...(Utils.hasClass( element, 'vstupzestitu-ne' ) ? [['HOBBY + KOUPELNA',8.50,'dlažba']] : []),   
                     ...(Utils.hasClass( element, 'vstupzestitu-ano' ) ? [['ZÁDVEŘÍ + KOUPELNA',8.50,'dlažba']] : []),                    
                    ['HALA',8.20,'dlažba'],                    
                    ['KOMORA',2.40,'dlažba'],
                    ...(Utils.hasClass( element, 'auto-garaz' ) ? [['GARÁŽ',17.40,'betonová mazanina']] : []),
                    ...(Utils.hasClass( element, 'auto-garaz' ) ? [['Celkový součet:', '81.40', '']] : [['Celkový součet:', '64.00', '']])    
                ] 
            },{
                headers: ['', '', ''],
                rows: [
                    ['GALERIE, SCHODIŠTĚ',6.50,'dlažba'],
                    ['KOUPELNA',8.90,'dlažba'],
                    ['LOŽNICE',15.20,'koberec'],
                    ['POKOJ',17.60,'koberec'],
                    ['PRACOVNA',11.50,'koberec'],
                    ['ŠATNA',3.80,'koberec'],
                    ['Celkový součet:', '63.50', '']
                ]
            }], 
            tech_info: "Předmětem návrhu je rodinný dům typ IDEAL CITY. Jedná se o izolovaný, nepodsklepený, jednopodlažní objekt s obytným podkrovím.\
 V přízemí objektu se nachází kuchyň/kuchyňský kout,\
 " + (Utils.hasClass( element, 'kuchynvobytnem-ano' ) ? "pracovna, " : "") + "obývací pokoj, WC/koupelna, komora, hobby a hala se schodištěm. V podkroví jsou pokoje, ložnice,\
 " + (Utils.hasClass( element, 'samostatnewc1np-Ano' ) ? "WC," : "šatna,") + " galerie a koupelna.",
            tech_info_roof: "Vrchol hřebene střechy-výšková kóta +6,458 m (vztaženo k +0,000 = PODLAHA 1.NP)",
            tech_info_list_title: "Konstrukce objektu je z velkoplošných dílců ECOBETON Canaba® tl. 150mm. Skladba konstrukce od vnější strany:",
            tech_info_list: ["stěrková strukturovaná omítka", "disperzní nátěr", "stěrkový tmel + výztužná tkanina VT1", "pěnový polystyren EPS 70 F tl. 200 mm na talířové hmoždinky", "lepící tmel", "dílec ECOBETON Canaba®", "Vnitřní nosné stěny jsou z dílců ECOBETON Canaba® typové tl. 150mm."],
            tech_info_etc1: "Stropní konstrukce je ze stropních dílců ECOBETON Canaba®. Stropní dílce jsou v tl. 150mm, podestový tl. 200mm. Ztužení objektu je provedeno betonovou zálivkou a zálivkovou výztuží.\n\nPodlahy v 1. NP  RD jsou betonové, s povrchovou úpravou dle tabulky podlah, ve 2.NP jsou podlahy nulové s nášlapnou vrstvou dle tabulky.\n\nObjekt je zastřešen sedlovou střechou se sklonem 14°. Krov je celodřevěný hambalkové soustavy s laťováním.\n\nVnitřní stěny objektu jsou opatřeny štukovou stěrkou na betonový povrch. Vnější povrch fasády tvoří strukturovaná omítka.",
            tech_info_etc2: "Dveře vnitřní jsou použity plné do obložkových zárubní. Vchodové, balkonové a lodžiové dveře budou typické materiál viz okna.\n\nIzolace tepelné v podlaze 1.NP jsou součástí dodávky spodní stavby a budou provedeny grafitovými deskami EPS 150 tl. 100mm (podlahy). Izolace tepelné v podkroví budou provedeny rohožemi z minerální vlny tl.290 mm. Izolace obvodových stěn je z EPS 70 F tl. 200 mm.\n\nStřecha bude provedena z betonových tašek, na dřevěné laťování. Oplechování střechy a parapetů, střešní svody, kotlíky a okapní žlaby jsou z titanzinkového plechu.\n\nMalby vnitřní budou disperzní bílé.\n\nDlažby budou provedeny keramické, dle výpisu podlah. Obklady budou keramické - na WC a v koupelně do výšky 2 m."
        };},
        'uno': ( element ) => { return {
            file: 'Uno.png',
            url: 'uno',
            title_short: 'UNO',
            title_long: 'rodinný dům Uno',
            subtitle: Utils.hasClass( element, 'orientace-zakladni' ) ? 'základní provedení' : 'zrcadlové provedení',
            investor: '',
            misto: '',
            datum: '',
            imgtoonepage: true,
            floorImageWidth: Utils.hasClass( element, 'auto-stani' ) || Utils.hasClass( element, 'auto-garaz' ) ? 450 : 512,   
            table: [{
                headers: ['', '', ''],
                rows: [
                    ['OBÝVACÍ POKOJ',24.40,'plovoucí podlaha'],
                    ['KUCHYŇ',10.10,'dlažba'],
                    ['HOBBY + KOUPELNA',5.10,'dlažba'],
                    ['HALA',8.50,'dlažba'],
                      //garaz
                    ...(Utils.hasClass( element, 'auto-garaz' ) ? [['GARÁŽ',17.40,'betonová mazanina']] : []),
                    ...(Utils.hasClass( element, 'auto-garaz' ) ? [['Celkový součet:', '65.50', '']] : [['Celkový součet:', '48.10', '']]),                    
                ] 
            },{
                headers: ['', '', ''],
                rows: [
                    ['GALERIE, SCHODIŠTĚ',8.20,'dlažba'],
                    ['KOUPELNA',5.50,'dlažba'],
                    ['LOŽNICE',14.50,'koberec'],
                    ['POKOJ',10.10,'koberec'],
                    ['POKOJ',9.60,'koberec'],
                    ['Celkový součet:', '47.90', '']
                ]
            }],
            tech_info: "Předmětem návrhu je rodinný dům typ UNO. Jedná se o izolovaný, nepodsklepený, jednopodlažní objekt s obytným podkrovím. V přízemí objektu se nachází kuchyň, obývací pokoj, WC/koupelna, hobby a hala se schodištěm. V podkroví jsou pokoje, ložnice, galerie a koupelna.",
            tech_info_roof: "Vrchol hřebene střechy – výšková kóta +7,140m (vytaženo k +0,000=PODLAHA 1.NP).",
            tech_info_list_title: "Konstrukce objektu je z velkoplošných dílců ECOBETON Canaba® tl. 100mm. Skladba konstrukce od vnější strany:",
            tech_info_list: ["stěrková strukturovaná omítka","disperzní nátěr","stěrkový tmel + výztužná tkanina VT1","pěnový polystyren EPS 70 F tl. 200 mm na talířové hmoždinky","lepící tmel","dílec ECOBETON Canaba®","Vnitřní nosné stěny jsou z dílců ECOBETON Canaba® typové tl. 150mm."],
            tech_info_etc1: "Stropní konstrukce je ze stropních dílců ECOBETON Canaba®. Stropní dílce jsou v tl. 150mm. Ztužení objektu je provedeno betonovou zálivkou a zálivkovou výztuží.\n\nPodlahy v 1. NP  RD jsou betonové, s povrchovou úpravou dle tabulky podlah, ve 2.NP jsou podlahy nulové s nášlapnou vrstvou dle tabulky.\n\nObjekt je zastřešen sedlovou střechou se sklonem 40°. Krov je celodřevěný hambalkové soustavy s laťováním.\n\nVnitřní stěny objektu jsou opatřeny štukovou stěrkou na betonový povrch. Vnější povrch fasády tvoří strukturovaná omítka.",
            tech_info_etc2: "Dveře vnitřní jsou použity plné do obložkových zárubní. Vchodové, balkonové a lodžiové dveře budou atypické materiál viz okna.\n\nIzolace tepelné v podlaze 1.NP jsou součástí dodávky spodní stavby a budou provedeny grafitovými deskami EPS 150 tl. 100mm (podlahy). Tepelná izolace střechy bude provedena z desek PIR tl. 140 mm. Izolace obvodových stěn je z EPS 70tl. 200 mm.\n\nStřecha bude provedena z betonových tašek Bramac, na dřevěné laťování. Oplechování střechy a parapetů, střešní svody, kotlíky a okapní žlaby jsou materiálu StabiCor-M.\n\nMalby vnitřní budou disperzní bílé.\n\nDlažby budou provedeny keramické, dle výpisu podlah. Obklady budou keramické - na WC a v koupelně do výšky 2,0 m."
        };}
        ,
        'unocity': ( element ) => { return {
            file: 'UnoCity.png',
            url: 'unocity',
            title_short: 'UNO CITY',
            title_long: 'rodinný dům Uno City',
            subtitle: Utils.hasClass( element, 'orientace-zakladni' ) ? 'základní provedení' : 'zrcadlové provedení',
            investor: '',
            misto: '',
            datum: '',
            imgtoonepage: true,
            floorImageWidth: Utils.hasClass( element, 'auto-stani' ) || Utils.hasClass( element, 'auto-garaz' ) ? 450 : 512,   
            table: [{
                headers: ['', '', ''],
                rows: [
                    ['OBÝVACÍ POKOJ',24.50,'plovoucí podlaha'],
                    ['KUCHYŇ',10.10,'dlažba'],
                    ['HOBBY + KOUPELNA',5.10,'dlažba'],
                    ['HALA',8.50,'dlažba'],
                      //garaz
                    ...(Utils.hasClass( element, 'auto-garaz' ) ? [['GARÁŽ',17.40,'betonová mazanina']] : []),
                    ...(Utils.hasClass( element, 'auto-garaz' ) ? [['Celkový součet:', '65.60', '']] : [['Celkový součet:', '48.20', '']]), 
                ] 
            },{
                headers: ['', '', ''],
                rows: [
                    ['GALERIE, SCHODIŠTĚ',8.20,'dlažba'],
                    ['KOUPELNA',5.50,'dlažba'],
                    ['LOŽNICE',14.50,'koberec'],
                    ['POKOJ',10.10,'koberec'],
                    ['POKOJ',9.70,'koberec'],
                    ['Celkový součet:', '48.00', '']
                ]
            }],
            tech_info: "Předmětem návrhu je rodinný dům typ UNO CITY. Jedná se o izolovaný, nepodsklepený, jednopodlažní objekt s obytným podkrovím. V přízemí objektu se nachází kuchyň, obývací pokoj, WC/koupelna, hobby a hala se schodištěm. V podkroví jsou pokoje, ložnice, galerie a koupelna.",
            tech_info_roof: "Vrchol hřebene střechy-výšková kóta +6,200 m (vztaženo k +0,000 = PODLAHA 1.NP)",
            tech_info_list_title: "Konstrukce objektu RD je z dílců ECOBETON Canaba® tl. 100mm. Skladba konstrukce od vnější strany:",
            tech_info_list: ["stěrková strukturovaná omítka","disperzní nátěr","stěrkový tmel + výztužná tkanina VT1","pěnový polystyren EPS 70 F tl. 200 mm na talířové hmoždinky","lepící tmel","ECOBETON Canaba® dílec tl. 150mm.","Vnitřní nosné stěny jsou z dílců ECOBETON Canaba® typové tl. 150mm."],
            tech_info_etc1: "Stropní konstrukce jsou z dílců ECOBETON Canaba®. Stropní dílce jsou v tl. 150mm. Ztužení objektu je provedeno betonovou zálivkou a zálivkovou výztuží.\n\nPodlahy v 1. NP  RD jsou betonové, s povrchovou úpravou dle tabulky podlah, ve 2.NP jsou podlahy nulové s nášlapnou vrstvou dle tabulky.\n\nObjekt je zastřešen sedlovou střechou se sklonem 14°. Nosnou konstrukci střechy tvoří střešní dílce ECOBETON Canaba® tloušťky 120 mm.\n\nVnitřní stěny objektu jsou opatřeny štukovou stěrkou na betonový povrch. Vnější povrch fasády tvoří strukturovaná omítka.",
            tech_info_etc2: "Dveře vnitřní jsou použity plné do obložkových zárubní. Vchodové, balkonové a lodžiové dveře budou atypické materiál viz okna.\n\nIzolace tepelné v podlaze 1.NP jsou součástí dodávky spodní stavby a budou provedeny grafitovými deskami EPS 150 tl. 100mm (podlahy). Tepelná izolace střechy bude provedena z desek PIR tl. 140 mm. Izolace obvodových stěn je z EPS 70tl. 200 mm.\n\nStřecha bude provedena z betonových tašek Bramac, na dřevěné laťování. Oplechování střechy a parapetů, střešní svody, kotlíky a okapní žlaby jsou materiálu StabiCor-M.\n\nMalby vnitřní budou disperzní bílé.\n\nDlažby budou provedeny keramické, dle výpisu podlah. Obklady budou keramické - na WC a v koupelně do výšky 2,0 m."
        };},
        'romance': ( element ) => { return {
            file: 'Romance.png',
            url: 'romance',
            title_short: 'ROMANCE',
            title_long: 'rodinný dům Romance',
            subtitle: Utils.hasClass( element, 'orientace-zakladni' ) ? 'základní provedení' : 'zrcadlové provedení',
            investor: '',
            misto: '',
            datum: '',
            imgtoonepage: false,
            floorImageWidth: Utils.hasClass( element, 'auto-stani' ) || Utils.hasClass( element, 'auto-garaz' ) ? 450 : 512,   
            table: [{
                headers: ['', '', ''],
                rows: [
                    ...(Utils.hasClass( element, 'kuchynvobytnem-ne' )&&Utils.hasClass( element, 'pracovna-ne' ) ? [['OBÝVACÍ POKOJ',24.30,'plovoucí podlaha'],['KUCHYŇ',11.50,'dlažba']] : []),
                    ...(Utils.hasClass( element, 'kuchynvobytnem-ano' )&&Utils.hasClass( element, 'pracovna-ne' ) ? [['OBÝVACÍ POKOJ+KK',24.30,'plovoucí podlaha/dlažba'],['PRACOVNA',11.50,'koberec']] : []),
                    ...(Utils.hasClass( element, 'kuchynvobytnem-ne' )&&Utils.hasClass( element, 'pracovna-ano' ) ? [['OBÝVACÍ POKOJ',14.40,'plovoucí podlaha'],['KUCHYŇ',11.50,'dlažba'],['PRACOVNA',9.10,'koberec']] : []),                    
                    //
                    ['JÍDELNA',12.80,'parkety'],                      
                    ...(Utils.hasClass( element, 'vstupzestitu-ne' ) ? [['HOBBY + KOUPELNA',8.50,'dlažba']] : []),   
                    ...(Utils.hasClass( element, 'vstupzestitu-ano' ) ? [['ZÁDVEŘÍ + KOUPELNA',8.50,'dlažba']] : []),                    
                    ['HALA',8.20,'dlažba'],                    
                    ['KOMORA',1.50,'dlažba'],
                    ...(Utils.hasClass( element, 'auto-garaz' ) ? [['GARÁŽ',17.40,'betonová mazanina']] : []),
                    ['Celkový součet:', '66.80', '']
                ] 
            },{
                headers: ['', '', ''],
                rows: [
                    ['GALERIE, SCHODIŠTĚ',6.70,'dlažba'],
                    ['KOUPELNA',7.40,'dlažba'],
                    ['LOŽNICE',19.70,'koberec'],
                    ['POKOJ',14.80,'koberec'],
                    ['POKOJ',11.50,'koberec'],
                    ...(Utils.hasClass( element, 'balkon-standardni' ) ? [['BALKONY',5.20,'dlažba']] : []),
                    ...(Utils.hasClass( element, 'balkon-rozsireny' ) ? [['BALKONY',7.40,'dlažba']] : []),
                    ...(Utils.hasClass( element, 'satna-ano' ) ? [['ŠATNA',3.70,'koberec']] : []),
                    ...(Utils.hasClass( element, 'samostatnewc1np-ano' ) ? [['WC',2.70,'dlažba']] : []),
                    ['Celkový součet:', '65.30', '']
                ]
            }], 
            tech_info: "Předmětem návrhu je rodinný dům typ ROMANCE. Jedná se o izolovaný, nepodsklepený, jednopodlažní objekt s obytným podkrovím.\
 V přízemí objektu se nachází kuchyň/kuchyňský kout,\
 " + (Utils.hasClass( element, 'pracovna-ano' ) || Utils.hasClass( element, 'kuchynvobytnem-ano' ) ? "pracovna, " : "") + "jídelna, obývací pokoj, WC/koupelna, komora, hobby a hala se schodištěm. V podkroví jsou pokoje, ložnice,\
 " + (Utils.hasClass( element, 'satna-ano' ) ? "šatna, " : "") + (Utils.hasClass( element, 'samostatnewc1np-ano' ) ? "WC, " : "") + "galerie a koupelna.",
            tech_info_roof: "Vrchol hřebene střechy – výšková kóta +8,300m (vytaženo k +0,000=PODLAHA 1.NP).",
            tech_info_list_title: "Konstrukce objektu je z velkoplošných dílců ECOBETON Canaba® tl. 150mm. Skladba konstrukce od vnější strany:",
            tech_info_list: ["stěrková strukturovaná omítka", "disperzní nátěr", "stěrkový tmel + výztužná tkanina VT1", "pěnový polystyren EPS 70 F tl. 200 mm na talířové hmoždinky", "lepící tmel", "dílec ECOBETON Canaba®", "Vnitřní nosné stěny jsou z dílců ECOBETON Canaba® typové tl. 150mm."],
            tech_info_etc1: "Stropní konstrukce je ze stropních dílců ECOBETON Canaba®. Stropní dílce jsou v tl. 150mm, podestový tl. 200mm. Ztužení objektu je provedeno betonovou zálivkou a zálivkovou výztuží.\n\nPodlahy v 1. NP  RD jsou betonové, s povrchovou úpravou dle tabulky podlah, ve 2.NP jsou podlahy nulové s nášlapnou vrstvou dle tabulky.\n\nObjekt je zastřešen sedlovou střechou se sklonem 45°. Krov je celodřevěný hambalkové soustavy s laťováním.\n\nVnitřní stěny objektu jsou opatřeny štukovou stěrkou na betonový povrch. Vnější povrch fasády tvoří strukturovaná omítka.",
            tech_info_etc2: "Dveře vnitřní jsou použity plné do obložkových zárubní. Vchodové, balkonové a lodžiové dveře budou typické materiál viz okna.\n\nIzolace tepelné v podlaze 1.NP jsou součástí dodávky spodní stavby a budou provedeny grafitovými deskami EPS 150 tl. 100mm (podlahy). Izolace tepelné v podkroví budou provedeny rohožemi z minerální vlny tl.290 mm. Izolace obvodových stěn je z EPS 70 F tl. 200 mm.\n\nStřecha bude provedena z betonových tašek, na dřevěné laťování. Oplechování střechy a parapetů, střešní svody, kotlíky a okapní žlaby jsou z titanzinkového plechu.\n\nMalby vnitřní budou disperzní bílé.\n\nDlažby budou provedeny keramické, dle výpisu podlah. Obklady budou keramické - na WC a v koupelně do výšky 2 m."
        };},
        'rezidence': ( element ) => { return {
            file: 'Rezidence.jpg',
            url: 'rezidence',
            title_short: 'REZIDENCE',
            title_long: 'rodinný dům Rezidence',
            subtitle: Utils.hasClass( element, 'orientace-zakladni' ) ? 'základní provedení' : 'zrcadlové provedení',
            investor: '',
            misto: '',
            datum: '',
            imgtoonepage: false,
            floorImageWidth: Utils.hasClass( element, 'auto-garaz' ) ? 400 : 450,   
            orthoHeight: Utils.hasClass( element, 'auto-garaz' ) ? 10 : 8,
            table: [{
                headers: ['', '', ''],
                rows: [
                    ['VSTUP',6.20,'dlažba'], 
                    ['HALA',14.00,'plovoucí podlaha'],                    
                    ['HOBBY',9.00,'dlažba'],
                    ['WC',1.80,'dlažba'],
                    ...(Utils.hasClass( element, 'pracovna-ano' ) ? [['OBÝVACÍ POKOJ',38.00,'plovoucí podlaha']] : [['OBÝVACÍ POKOJ',52.00,'plovoucí podlaha']]),  
                    ...(Utils.hasClass( element, 'pracovna-ano' ) ? [['PRACOVNA',14.00,'plovoucí podlaha']] : []),                      
                    ...(Utils.hasClass( element, 'auto-garaz' ) ? [['GARÁŽ',17.40,'betonová mazanina']] : []),
                    ['KUCHYŇ',14.00,'dlažba'],                    
                    ['Celkový součet:', '97.00', '']
                ] 
            },{
                headers: ['', '', ''],
                rows: [
                    ['GALERIE, SCHODIŠTĚ',12.00,'dlažba'],
                    ...(Utils.hasClass( element, 'satna-ano' ) ? [['LOŽNICE',22.50,'koberec']] : [['LOŽNICE',31.00,'koberec']]),  
                    ...(Utils.hasClass( element, 'satna-ano' ) ? [['ŠATNA',8.50,'koberec']] : []),                    
                    ['KOUPELNA',13.00,'dlažba'],                    
                    ['BALKON',8.00,'dlažba'],                     
                    ['POKOJ',14.00,'koberec'],
                    ['POKOJ',14.00,'koberec'],
                    ['KOUPELNA',5.00,'dlažba'],                    
                    ['Celkový součet:', '97.00', '']
                ]
            }], 
            tech_info: "Předmětem návrhu je rodinný dům typ REZIDENCE. Jedná se o izolovaný, nepodsklepený, jednopodlažní objekt s obytným podkrovím.\
 V přízemí objektu se nachází kuchyň/kuchyňský kout,\
 " + (Utils.hasClass( element, 'pracovna-ano' ) || Utils.hasClass( element, 'kuchynvobytnem-ano' ) ? "pracovna, " : "") + "jídelna, obývací pokoj, WC/koupelna, komora, hobby a hala se schodištěm. V podkroví jsou pokoje, ložnice,\
 " + (Utils.hasClass( element, 'satna-ano' ) ? "šatna, " : "") + (Utils.hasClass( element, 'samostatnewc1np-ano' ) ? "WC, " : "") + "galerie a koupelna.",
            tech_info_roof: "Vrchol hřebene střechy – výšková kóta +8,300m (vytaženo k +0,000=PODLAHA 1.NP).",
            tech_info_list_title: "Konstrukce objektu je z velkoplošných dílců ECOBETON Canaba® tl. 150mm. Skladba konstrukce od vnější strany:",
            tech_info_list: ["stěrková strukturovaná omítka", "disperzní nátěr", "stěrkový tmel + výztužná tkanina VT1", "pěnový polystyren EPS 70 F tl. 200 mm na talířové hmoždinky", "lepící tmel", "dílec ECOBETON Canaba®", "Vnitřní nosné stěny jsou z dílců ECOBETON Canaba® typové tl. 150mm."],
            tech_info_etc1: "Stropní konstrukce je ze stropních dílců ECOBETON Canaba®. Stropní dílce jsou v tl. 150mm, podestový tl. 200mm. Ztužení objektu je provedeno betonovou zálivkou a zálivkovou výztuží.\n\nPodlahy v 1. NP  RD jsou betonové, s povrchovou úpravou dle tabulky podlah, ve 2.NP jsou podlahy nulové s nášlapnou vrstvou dle tabulky.\n\nObjekt je zastřešen sedlovou střechou se sklonem 25°. Krov je celodřevěný hambalkové soustavy s laťováním.\n\nVnitřní stěny objektu jsou opatřeny štukovou stěrkou na betonový povrch. Vnější povrch fasády tvoří strukturovaná omítka.",
            tech_info_etc2: "Dveře vnitřní jsou použity plné do obložkových zárubní. Vchodové, balkonové a lodžiové dveře budou typické materiál viz okna.\n\nIzolace tepelné v podlaze 1.NP jsou součástí dodávky spodní stavby a budou provedeny grafitovými deskami EPS 150 tl. 100mm (podlahy). Izolace tepelné v podkroví budou provedeny rohožemi z minerální vlny tl.290 mm. Izolace obvodových stěn je z EPS 70 F tl. 200 mm.\n\nStřecha bude provedena z betonových tašek, na dřevěné laťování. Oplechování střechy a parapetů, střešní svody, kotlíky a okapní žlaby jsou z titanzinkového plechu.\n\nMalby vnitřní budou disperzní bílé.\n\nDlažby budou provedeny keramické, dle výpisu podlah. Obklady budou keramické - na WC a v koupelně do výšky 2 m."
        };},
        'harmonie': ( element ) => { return {
            file: 'Harmonie.jpg',
            url: 'harmonie',
            title_short: 'HARMONIE',
            title_long: 'rodinný dům Harmonie',
            subtitle: Utils.hasClass( element, 'orientace-zakladni' ) ? 'základní provedení' : 'zrcadlové provedení',
            investor: '',
            misto: '',
            datum: '',
            imgtoonepage: false,
            floorImageWidth: 450,  
            table: [{
                headers: ['', '', ''],
                rows: [
                    ...( Utils.hasClass( element, 'kuchynvobytnem-ne' ) && Utils.hasClass( element, 'pracovna-ne' ) ? [['OBÝVACÍ POKOJ',33.40,'plovoucí podlaha'],['KUCHYŇ',11.50,'dlažba']] : []),  
                    ...(Utils.hasClass( element, 'kuchynvobytnem-ano' ) ? [['OBÝVACÍ POKOJ+KK',33.40,'plovoucí podlaha/dlažba'],['POKOJ',11.50,'koberec']] : []),  
                    ...(Utils.hasClass( element, 'pracovna-ano' ) ? [['OBÝVACÍ POKOJ',24.30,'plovoucí podlaha'],['PRACOVNA',9.10,'koberec'],['KUCHYŇ',11.50,'dlažba']] : []),
                    ['HOBBY+KOUPELNA',8.70,'dlažba'],
                    ['HALA',8.70,'dlažba'],                     
                    ['VSTUP',4.70,'dlažba'], 
                    ['KOMORA',2.50,'dlažba'],
                    ...(Utils.hasClass( element, 'rozsirenigaraze-ne' ) ? [['SKLAD/GARÁŽ',14.96,'dlažba']] : [['SKLAD/GARÁŽ',18.11,'dlažba']]), 
                    ['Celkový součet:', '88.20', '']
                ] 
            },{
                headers: ['', '', ''],
                rows: [
                    ['GALERIE, SCHODIŠTĚ',10.40,'dlažba'],
                    ['KOUPELNA',8.50,'dlažba'],                    
                    ['LOŽNICE',15.20,'koberec'],                    
                    ['POKOJ',17.60,'koberec'],
                    ['POKOJ',11.50,'koberec'],
                    ...(Utils.hasClass( element, 'rozsirenigaraze-ne' ) ? [['STUDIO',24.40,'koberec']] : [['STUDIO',27.56,'koberec']]), 
                    ['Celkový součet:', '87.60', '']
                ]
            }], 
            tech_info: "Předmětem návrhu je rodinný dům typ HARMONIE. Jedná se o izolovaný, nepodsklepený, jednopodlažní objekt s obytným podkrovím.\
 V přízemí objektu se nachází kuchyň/kuchyňský kout\
 " + (Utils.hasClass( element, 'pracovna-ano' ) ? ", pracovna" : "") + ", obývací pokoj" + (Utils.hasClass( element, 'kuchynvobytnem-ano' ) ? ", pokoj" : "") + ", WC/koupelna, komora, hobby, sklad/garáž a hala se schodištěm. V podkroví jsou pokoje, ložnice, studio, \
 galerie a koupelna.",
            tech_info_roof: "Vrchol hřebene střechy – výšková kóta +8,300m (vytaženo k +0,000=PODLAHA 1.NP).",
            tech_info_list_title: "Konstrukce objektu je z velkoplošných dílců ECOBETON Canaba® tl. 150mm. Skladba konstrukce od vnější strany:",
            tech_info_list: ["stěrková strukturovaná omítka", "disperzní nátěr", "stěrkový tmel + výztužná tkanina VT1", "pěnový polystyren EPS 70 F tl. 200 mm na talířové hmoždinky", "lepící tmel", "dílec ECOBETON Canaba®", "Vnitřní nosné stěny jsou z dílců ECOBETON Canaba® typové tl. 150mm."],
            tech_info_etc1: "Stropní konstrukce je ze stropních dílců ECOBETON Canaba®. Stropní dílce jsou v tl. 150mm, podestový tl. 200mm. Ztužení objektu je provedeno betonovou zálivkou a zálivkovou výztuží.\n\nPodlahy v 1. NP  RD jsou betonové, s povrchovou úpravou dle tabulky podlah, ve 2.NP jsou podlahy nulové s nášlapnou vrstvou dle tabulky.\n\nObjekt je zastřešen sedlovou a valbovou střechou. Krov je celodřevěný hambalkové soustavy s laťováním. \n\nVnitřní stěny objektu jsou opatřeny štukovou stěrkou na betonový povrch. Vnější povrch fasády tvoří strukturovaná omítka.",
            tech_info_etc2: "Dveře vnitřní jsou použity plné do obložkových zárubní. Vchodové, balkonové a lodžiové dveře budou typické materiál viz okna.\n\nIzolace tepelné v podlaze 1.NP jsou součástí dodávky spodní stavby a budou provedeny grafitovými deskami EPS 150 tl. 100mm (podlahy). Izolace tepelné v podkroví budou provedeny rohožemi z minerální vlny tl.290 mm. Izolace obvodových stěn je z EPS 70 F tl. 200 mm.\n\nStřecha bude provedena z betonových tašek, na dřevěné laťování. Oplechování střechy a parapetů, střešní svody, kotlíky a okapní žlaby jsou z titanzinkového plechu.\n\nMalby vnitřní budou disperzní bílé.\n\nDlažby budou provedeny keramické, dle výpisu podlah. Obklady budou keramické - na WC a v koupelně do výšky 2 m."
        };},
        'bungalov': ( element ) => { return {
            file: 'Bungalov.jpg',
            url: 'bungalov',
            title_short: 'BUNGALOV',
            title_long: 'rodinný dům Bungalov',
            subtitle: Utils.hasClass( element, 'orientace-zakladni' ) ? 'základní provedení' : 'zrcadlové provedení',
            investor: '',
            misto: '',
            datum: '',
            imgtoonepage: false,
            floorImageWidth: 400,
            floorPosY: 200,
            tabPosY: 600,
            orthoHeight: Utils.hasClass( element, 'auto-stani' ) ? 10 : 8,
            table: [{
                headers: ['', '', ''],
                rows: [
                    ...(Utils.hasClass( element, 'kuchynvhobby-ano' ) ? [ ['OBÝVACÍ POKOJ+KK',37.70,'plovoucí podlaha/dlažba'], ['LOŽNICE',15.00,'koberec']] : [ ['OBÝVACÍ POKOJ',28.60,'plovoucí podlaha'], ['KUCHYŇ',15.00,'dlažba'],['HOBBY',9.10,'dlažba']]),
              
                    ['ZÁDVEŘÍ',7.10,'dlažba'], 
                    ['CHODBA',16.30,'dlažba'],                        
                    ['KOUPELNA',6.40,'dlažba'],
                    ['LOŽNICE',13.30,'koberec'],
                    ['POKOJ',8.10,'koberec'],
                    ['POKOJ',11.60,'koberec'],                    
                    ['KRYTÁ TERASA',10.00,'zámková dlažba'],
                    ['WC/KOUPELNA',3.00,'dlažba'],
                    ['Celkový součet:', '128.50', '']
                ] 
            }], 
            tech_info: "Předmětem návrhu je rodinný dům typ BUNGALOV. Jedná se o izolovaný, nepodsklepený, jednopodlažní objekt, nepravidelného půdorysného tvaru.\
 V objektu se nachází zádveří, kuchyň/kuchyňský kout, pokoje, ložnice, obývací pokoj, WC/koupelna" + (Utils.hasClass( element, 'kuchynvhobby-ne' ) ? ", hobby" : ".") + " a krytá terasa.",
            tech_info_roof: "Vrchol hřebene střechy – výšková kóta +5,195 m (vytaženo k +0,000=PODLAHA 1.NP).",
            tech_info_list_title: "Konstrukce objektu je z velkoplošných dílců ECOBETON Canaba® tl. 150mm. Skladba konstrukce od vnější strany:",
            tech_info_list: ["stěrková strukturovaná omítka", "disperzní nátěr", "stěrkový tmel + výztužná tkanina VT1", "pěnový polystyren EPS 70 F tl. 200 mm na talířové hmoždinky", "lepící tmel", "dílec ECOBETON Canaba®", "Vnitřní nosné stěny jsou z dílců ECOBETON Canaba® typové tl. 150mm."],
            tech_info_etc1: "Stropní konstrukce je ze stropních dílců ECOBETON Canaba® tl. 150mm. Ztužení objektu je provedeno betonovou zálivkou a zálivkovou výztuží.\n\nPodlahy RD jsou betonové, s povrchovou úpravou dle tabulky podlah.\n\nObjekt je zastřešen valbovou střechou. Krov je celodřevěný hambalkové soustavy s laťováním.\n\nVnitřní stěny objektu jsou opatřeny štukovou stěrkou na betonový povrch. Vnější povrch fasády tvoří strukturovaná omítka.",
            tech_info_etc2: "Dveře vnitřní jsou použity plné do obložkových zárubní. Vchodové a lodžiové dveře budou typické materiál viz okna.\n\nIzolace tepelné v podlaze 1.NP jsou součástí dodávky spodní stavby a budou provedeny grafitovými deskami EPS 150 tl. 100mm (podlahy). Izolace tepelné v podkroví budou provedeny rohožemi z minerální vlny tl.290 mm. Izolace obvodových stěn je z EPS 70 F tl. 200 mm.\n\nStřecha bude provedena z betonových tašek, na dřevěné laťování. Oplechování střechy a parapetů, střešní svody, kotlíky a okapní žlaby jsou z titanzinkového plechu.\n\nMalby vnitřní budou disperzní bílé.\n\nDlažby budou provedeny keramické, dle výpisu podlah. Obklady budou keramické - na WC a v koupelně do výšky 2 m."
        };},
        'riviera': ( element ) => { return {
            file: 'Riviera.jpg',
            url: 'riviera',
            title_short: 'RIVIERA',
            title_long: 'rodinný dům Riviera',
            subtitle: Utils.hasClass( element, 'orientace-zakladni' ) ? 'základní provedení' : 'zrcadlové provedení',
            investor: '',
            misto: '',
            datum: '',
            imgtoonepage: false,
            floorImageWidth: 450,
            floorPosY: 200,            
            table: [{
                headers: ['', '', ''],
                rows: [
                    ['OBÝVACÍ POKOJ',45.10,'plovoucí podlaha'],
                    ['KUCHYŇ',19.70,'dlažba'],
                    ['HOBBY',6.30,'dlažba'],
                    ['HALA',4.70,'dlažba'],
                    ['SPÍŽ + KOUPELNA',4.30,'dlažba'],
                    ['KOMORA',2.40,'dlažba'],
                    ['SKLAD/GARÁŽ',16.40,'dlažba'],
                    ['KRYTÝ VSTUP',6.30,'základová deska'],
                    ['KRYTÁ TERASA',19.80,'základová deska'],
                    ['Celkový součet:', '125.00', '']
                ] 
            },{
                headers: ['', '', ''],
                rows: [
                    ['GALERIE, SCHODIŠTĚ',9.90,'dlažba'],
                    ['KOUPELNA',7.40,'dlažba'],                    
                    ['LOŽNICE',37.90,'koberec'],                    
                    ['POKOJ',23.50,'koberec'],
                    ['POKOJ',25.00,'koberec'],
                    ['ŠATNA',7.80,'koberec'],
                    ['ŠATNA',7.80,'koberec'],                    
                    ['Celkový součet:', '119.30', '']
                ]
            }], 
            tech_info: "Předmětem návrhu je rodinný dům typ RIVIERA. Jedná se o izolovaný, nepodsklepený, jednopodlažní objekt s obytným podkrovím.\
 V přízemí objektu se nachází kuchyň, obývací pokoj, komora, hobby, sklad/garáž a hala se schodištěm. V podkroví jsou pokoje, ložnice, šatna, \
 galerie a koupelna.",
            tech_info_roof: "Vrchol hřebene střechy – výšková kóta +8,300m (vytaženo k +0,000=PODLAHA 1.NP).",
            tech_info_list_title: "Konstrukce objektu je z velkoplošných dílců ECOBETON Canaba® tl. 150mm. Skladba konstrukce od vnější strany:",
            tech_info_list: ["stěrková strukturovaná omítka", "disperzní nátěr", "stěrkový tmel + výztužná tkanina VT1", "pěnový polystyren EPS 70 F tl. 200 mm na talířové hmoždinky", "lepící tmel", "dílec ECOBETON Canaba®", "Vnitřní nosné stěny jsou z dílců ECOBETON Canaba® typové tl. 150mm."],
            tech_info_etc1: "Stropní konstrukce je ze stropních dílců ECOBETON Canaba®. Stropní dílce jsou v tl. 150mm, podestový tl. 200mm. Ztužení objektu je provedeno betonovou zálivkou a zálivkovou výztuží.\n\nPodlahy v 1. NP  RD jsou betonové, s povrchovou úpravou dle tabulky podlah, ve 2.NP jsou podlahy nulové s nášlapnou vrstvou dle tabulky.\n\nObjekt je zastřešen sedlovou a valbovou střechou. Krov je celodřevěný hambalkové soustavy s laťováním. \n\nVnitřní stěny objektu jsou opatřeny štukovou stěrkou na betonový povrch. Vnější povrch fasády tvoří strukturovaná omítka.",
            tech_info_etc2: "Dveře vnitřní jsou použity plné do obložkových zárubní. Vchodové, balkonové a lodžiové dveře budou typické materiál viz okna.\n\nIzolace tepelné v podlaze 1.NP jsou součástí dodávky spodní stavby a budou provedeny grafitovými deskami EPS 150 tl. 100mm (podlahy). Izolace tepelné v podkroví budou provedeny rohožemi z minerální vlny tl.290 mm. Izolace obvodových stěn je z EPS 70 F tl. 200 mm.\n\nStřecha bude provedena z betonových tašek, na dřevěné laťování. Oplechování střechy a parapetů, střešní svody, kotlíky a okapní žlaby jsou z titanzinkového plechu.\n\nMalby vnitřní budou disperzní bílé.\n\nDlažby budou provedeny keramické, dle výpisu podlah. Obklady budou keramické - na WC a v koupelně do výšky 2 m."
        };},
        'villa-tosca': ( element ) => { return {
            file: 'Tosca.jpg',
            url: 'villa-tosca',
            title_short: 'VILLA TOSCA',
            title_long: 'rodinný dům Villa Tosca',
            subtitle: Utils.hasClass( element, 'orientace-zakladni' ) ? 'základní provedení' : 'zrcadlové provedení',
            investor: '',
            misto: '',
            datum: '',
            imgtoonepage: false,
            floorImageWidth: 450,
            floorPosY: 200,
            //orthoHeight: Utils.hasClass( element, 'auto-stani' ) ? 10 : 8,
            table: [{
                headers: ['', '', ''],
                rows: [
                    ['OBÝVACÍ POKOJ',23.70,'plovoucí podlaha'],
                    ['KUCHYŇ',16.60,'dlažba'],
                    ['LOŽNICE',11.60,'koberec'],                    
                    ['POKOJ',11.60,'koberec'],
                    ['HALA',6.30,'dlažba'],    
                    ...(Utils.hasClass( element, 'rozsirenakoupelna-ne' ) ? [['KOUPELNA',4.70,'dlažba'],['KOMORA',2.90,'dlažba']] : [['KOUPELNA',7.60,'dlažba']]),                
                    ['WC',1.60,'dlažba'],
                    ['KRYTÁ TERASA',8.00,'dlažba'],                    
                    ['Celkový součet:', '87.00', '']
                ] 
            }], 
            tech_info: "Předmětem návrhu je rodinný dům typ VILLA TOSCA. Jedná se o izolovaný, nepodsklepený, jednopodlažní objekt, nepravidelného půdorysného tvaru.\
 V objektu se nachází zádveří, kuchyň, pokoj, ložnice, obývací pokoj, WC, hala a krytá terasa.",
            tech_info_roof: "Vrchol hřebene střechy – výšková kóta +5,195 m (vytaženo k +0,000=PODLAHA 1.NP).",
            tech_info_list_title: "Konstrukce objektu je z velkoplošných dílců ECOBETON Canaba® tl. 150mm. Skladba konstrukce od vnější strany:",
            tech_info_list: ["stěrková strukturovaná omítka", "disperzní nátěr", "stěrkový tmel + výztužná tkanina VT1", "pěnový polystyren EPS 70 F tl. 200 mm na talířové hmoždinky", "lepící tmel", "dílec ECOBETON Canaba®", "Vnitřní nosné stěny jsou z dílců ECOBETON Canaba® typové tl. 150mm."],
            tech_info_etc1: "Stropní konstrukce je ze stropních dílců ECOBETON Canaba® tl. 150mm. Ztužení objektu je provedeno betonovou zálivkou a zálivkovou výztuží.\n\nPodlahy RD jsou betonové, s povrchovou úpravou dle tabulky podlah.\n\nObjekt je zastřešen valbovou střechou. Krov je celodřevěný hambalkové soustavy s laťováním.\n\nVnitřní stěny objektu jsou opatřeny štukovou stěrkou na betonový povrch. Vnější povrch fasády tvoří strukturovaná omítka.",
            tech_info_etc2: "Dveře vnitřní jsou použity plné do obložkových zárubní. Vchodové a lodžiové dveře budou typické materiál viz okna.\n\nIzolace tepelné v podlaze 1.NP jsou součástí dodávky spodní stavby a budou provedeny grafitovými deskami EPS 150 tl. 100mm (podlahy). Izolace tepelné v podkroví budou provedeny rohožemi z minerální vlny tl.290 mm. Izolace obvodových stěn je z EPS 70 F tl. 200 mm.\n\nStřecha bude provedena z betonových tašek, na dřevěné laťování. Oplechování střechy a parapetů, střešní svody, kotlíky a okapní žlaby jsou z titanzinkového plechu.\n\nMalby vnitřní budou disperzní bílé.\n\nDlažby budou provedeny keramické, dle výpisu podlah. Obklady budou keramické - na WC a v koupelně do výšky 2 m."
        };}
    }
};