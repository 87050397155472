var template = require('~/js/template/content/nested/house/titleTemplate.html'),
	App = require('~/js/App'),
    data = require('~/js/Data');

module.exports = Backbone.View.extend({
       
    id: 'title',

    initialize: function( id ) {
        this.houseId = id;
        this.render();
    },

    render: function() {
        var obj = data.house[ this.houseId ]( document.getElementById( this.houseId ) );
        //
        this.el.innerHTML = template( { data: obj } );        
        return this;
    },

    update: function( id ) {
        this.houseId = id;
        this.render();
    },

    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }
});