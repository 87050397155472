var template = require('~/js/template/layout/mainMenuTemplate.html'),
	App = require('~/js/App'),
    Utils = require('~/js/Utils');


module.exports = Backbone.View.extend({

	id: "mainmenu",

    className: 'menu',

    events: {
    	"click .top":  "onLogoClick",
    	"click .bottom":  "onQualityClick"
    },

    initialize: function() {
    	this.quality = 3;
        this.render();
    },

    render: function() {
        this.el.innerHTML = template( {} );    
        //
		this.mainmenu = this.el;
		// tabs elems
		this.mainmenuicons = this.mainmenu.querySelectorAll( 'ul > li:not(.ignore)' ); 
		//radio groups from tabs
		this.radiogroups = this.mainmenu.querySelectorAll( 'fieldset[type="radiogroup"]' );
		//
		this.addEvents();
        return this;
    },

    update: function( id ) {
    	if ( App.user.get("logged_in") )
    		Utils.toggleClassByPrefix( document.body, 'house-' + id, 'house-' );
    	//
		Array.prototype.forEach.call( document.body.querySelectorAll('#tab0 #house input'), function( field, j ){
            if (  field.value === id )
                field.checked = true;
            else
            	field.checked = false;
        });    	
    },

    setUI: function( id, css ) {
    	var target = this.mainmenu.querySelector( '#' + id );
    	//
    	Array.prototype.forEach.call( css, ( style, i ) => {
    		var element = target.querySelector( '#' + style.split('-')[0] );
    		var value = style.split('-')[1];
    		//
    		Array.prototype.forEach.call( element.querySelectorAll( 'input[type="radio"]' ), (field, j ) => {
	            if (  field.value === value )
	                field.checked = true;
	            else
	            	field.checked = false;    			
    		});
    	});
    },

    setCSS: function( id, css ) {
    	var target = this.mainmenu.querySelector( '#' + id );
    	var classList = Object.keys( css ).map((key) => css[key] );
    	target.classList.value = '';

		async function asyncForEach( that, array ) {
			for (const item of array) {

	    		await Utils.addClass( target, item );
	    		setTimeout(() => { console.log("dealy"); }, 100);
			}
    		App.pc.app.fire('form:change'); 			
		}    	
		asyncForEach( this, classList );
    	//
    	this.setUI( id, classList );
    },    

    addEvents: function() {
		var that = this;
		//add tabs events
	    Array.prototype.forEach.call( this.mainmenuicons, function( el, i ){
	    	el._index = i;
			el.addEventListener('click', that.onTabClick, false );    		
	    });
	    //add radiogroups events
	    Array.prototype.forEach.call( this.radiogroups, function( groupel, i ){
	    	var temp = groupel.querySelectorAll( 'input[type="radio"]' );	
	        var state = groupel.parentElement.getAttribute('state'); //all, 0np, 1np
	        //
	    	Array.prototype.forEach.call( temp, function( el, j ){
	    		if ( el.checked) {
	    			that.onRadioChange( that.mainmenu, that.radiogroups[ i ], temp[ j ], null );
	    		}
	    		el.addEventListener('change', function(index, el) { that.onRadioChange( that.mainmenu, that.radiogroups[ i ], temp[ index ], state ); }.bind(el, j));  
	    	});
	    });
    },

    onLogoClick: function( evt ) {
        evt.preventDefault();
        Backbone.history.navigate( '/', true );
    },   

    onQualityClick: function( evt ) {
        evt.preventDefault();
        this.quality = (this.quality+1) % 4;
        // /
	    App.pc.app.fire('menu:quality', this.quality+1 );
    },    


	checkRadioGroups: function() {
		//kdyz zakazu kokretni radiobutton a ne celou grupu, musim checked button odstranit z classlistu a zaroven ho odchecknout
		var that = this;	
	    //check radiogroups to checked and not visible
	    Array.prototype.forEach.call( this.radiogroups, function( groupel, i ){
	    	var temp = groupel.querySelectorAll( 'input[type="radio"]' );	
	    	Array.prototype.forEach.call( temp, function( el, j ){
	    		if ( el.checked &&  window.getComputedStyle( el.parentElement ).display == 'none' ) {
	    			var targetstr = that.radiogroups[ i ].getAttribute('targetid');
					var targetelement = ( targetstr == null ? document.body : document.getElementById( targetstr )  );
	    			//
	    			el.checked = false;
	    			Utils.toggleClass( targetelement, that.radiogroups[ i ].id + '-' + el.value );
	    		}
	    	});
	    });
	},

	setDepends: function( target, targetelement, group ) {
	    var depend = target.getAttribute('setdepend').split(',');
	    Array.prototype.forEach.call( depend, function( item, i ){
	        var id = item.split(':')[0];
	        var val = item.split(':')[1].split('||');
	        //
	        var fields = targetelement.querySelectorAll( '#' + id + ' input[type="radio"]' );
	        Array.prototype.forEach.call( fields, function( field, j ){
	            if ( val.includes( field.value ) && field.checked && val.length > 1  ) {
	            	var index = val.indexOf( field.value );
	                field.checked = true;
	                Utils.toggleClassByPrefix( targetelement, id + '-' + val[ index ], id + '-' );
	            } else if ( val.includes( field.value ) && val.length === 1  ) {
	            	var index = val.indexOf( field.value );
	                field.checked = true;
	                Utils.toggleClassByPrefix( targetelement, id + '-' + val[ index ], id + '-' );
	            } else {
	            	field.checked = false;
	            }
	        }, this );
	        //if field not found, set them to zero
	        if ( Array.prototype.slice.call(fields).filter( e => e.checked === true ).length === 0 ) {
	        	fields[0].checked = true;
	        	Utils.toggleClassByPrefix( targetelement, id + '-' + fields[0].value, id + '-' );
	        }
	    }, this);

	},

	//handlers
	onTabClick: function( evt ) {
		evt.preventDefault();
		var index = evt.currentTarget._index;
		if ( Utils.hasClass( document.body, 'mainmenu-' + index ) ) {
			//click on seme tab ->close menu
			Utils.removeClass( document.body, 'mainmenu-' + index );
		} else {
			Utils.toggleClassByPrefix( document.body, 'mainmenu-' + index, 'mainmenu-' );
		}
	},

	onRadioChange: function( content, group, target, state ) {
		//console.log("_onRadioChange", content, group, target, state );
		var targetstr = group.getAttribute('targetid');
		var targetelement = ( targetstr == null ? document.body : this.mainmenu.querySelector( '#' + targetstr )  );
		//
		//set class on body or on to specified house div
		Utils.toggleClassByPrefix( targetelement, group.id + '-' + target.value, group.id + '-' );
	    //set state house (all, 0np, 1np) to the body
	    if ( state !== null )
	        Utils.toggleClassByPrefix( document.body, 'state-' + state, 'state-' );    
		//
		this.checkRadioGroups();
	    //
	    if ( target.getAttribute('setdepend') )
	        this.setDepends( target, targetelement, group );    

	    //main menu docasne
		if ( group.getAttribute('targetUrl') ) {
			Backbone.history.navigate( target.value, true );			
		}
	    //
	    if ( App.hasOwnProperty('pc')) {
	    	App.pc.app.fire('form:change'); //, { group: group }
	    }
	},

	dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }
});

