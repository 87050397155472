import protoScript from "~/js/lib/ProtoScript";
import Utils from '~/js/Utils';

var MorphByStyle = function(){
    this.name = 'morphByStyle';
    //
    this.attributes = {
        'element': { type: "string", default:"" }
    };

	MorphByStyle.prototype.initialize = function() {
	    this.element = document.querySelector( "#" + this.element );
	    //add listeners
	    this.observer = new MutationObserver( this.onMutationObserve.bind(this) );
	    this.observer.observe( this.element, { attributes: true });	  	
	};

	MorphByStyle.prototype.setData = function( data, model ) {
		this.data = [];
		for (var key in data ) {
			var mi = model.meshInstances.filter( item => { return item.node.name === key; }); 
			if ( mi.length && mi[0].morphInstance) {
				data[ key ].forEach( ( item, i ) => {
					var obj = {};
					obj.class = item.class.split(',');
					obj.condition = item.condition.toLowerCase();
					obj.isMorph = ( key )=> { 
						return this.data[key].class[ this.data[key].condition ]( (el) => { return Utils.hasClass( this.element, el ) === true; });
					};
					obj.meshInstances = mi;
					obj.morphTarget = parseInt( item.morphNumber ) - 1;
					obj.morphTime = parseFloat( item.morphTime.replace(/,/, '.') );
					this.data[ key + "_" + i ] = obj;
				});
			}
		};

		this.onAttributeChange(0.01 );		
	};

	//handlers
	MorphByStyle.prototype.onMutationObserve = function( mutations ) {
		mutations.forEach((mutation) => {
			if ( mutation.type == "attributes" )
				this.onAttributeChange();
		});
	};
	MorphByStyle.prototype.onAttributeChange = function( time ) {
		for (var key in this.data ) {
			if ( this.data[key].isMorph(key) ) {
				this.tweenMorph( 1, time, this.data[key] );
			} else {
				this.tweenMorph( 0, time, this.data[key] );
			}
		}
	};


	//tweening
	MorphByStyle.prototype.tweenMorph = function( weight, time, data ) {
		if ( data.currWeight !== weight ) {
			data.currWeight = weight;
		    data.meshInstances.forEach( ( mi, i ) => {
	    	    var tweenData = { weight: weight === 0 ? 1 : 0 };
		        time = time === undefined ? data.morphTime : time ;
				//
				this.entity
				.tween( tweenData )
				.to( { weight: weight } , time, pc.QuarticInOut )
				.on('update', function () {
					mi.morphInstance.setWeight( data.morphTarget, tweenData.weight );
				})
				.on('complete', function () {    

				})
				.start();
		    });
		}
	};	

};
export default protoScript( MorphByStyle );
//