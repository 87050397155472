import * as pc from "playcanvas";
import Utils from '~/js/Utils';

export default class Quality extends pc.ScriptType{

    initialize() {
    	this.quality = 4;
    	Utils.addClass( document.body, 'quality-' + this.quality );

	    this.numberOfDataPoints = 0;
	    this.currentAverageFps = 1;

	    this.stability = 0;
	    this.flickering = 0;
	    this.lastMovementWasDown = false;
	    this.light = this.app.root.findByName("light");
	    //
	    this.interval = setInterval( this.AdaptQuality.bind( this ), this.timeIntervalToAdaptQualitySettings * 1000 );
	    //   
	    this.on('attr:quality', this.onQualityChange );
	    this.app.on('menu:quality', this.onMenuChange, this );
    }

    onMenuChange( value ) {
	    this.quality = value;
    }

	onQualityChange( value ) {
	    console.log("quality change", value );
	    switch (value) {
	        case 1:
	            this.light.light.castShadows = false;
	            this.app.graphicsDevice.maxPixelRatio = 1;
	            this.entity.script.sao.enabled = false;
	            this.entity.script.sao.saoBlurEnabled = false;
	            break;
	        case 2:
	            this.light.light.castShadows = true;
	            //this.light.light.shadowUpdateMode = pc.SHADOWUPDATE_THISFRAME;
	            //this.light.light.vsmBlurSize = window.devicePixelRatio > 1 ? 12 : 6;
	            this.app.graphicsDevice.maxPixelRatio = 1.5;
	            this.entity.script.sao.enabled = false;
	            this.entity.script.sao.saoBlurEnabled = false;
	            break;
	        case 3:
	            this.light.light.castShadows = true;
	            //this.light.light.shadowUpdateMode = pc.SHADOWUPDATE_REALTIME;
	            //this.light.light.vsmBlurSize = window.devicePixelRatio > 1 ? 12 : 6;
	            this.app.graphicsDevice.maxPixelRatio = window.devicePixelRatio;
	            this.entity.script.sao.enabled = true;
	            this.entity.script.sao.saoBlurEnabled = false;
	            break;
	        case 4:
	            this.light.light.castShadows = true;
	            //this.light.light.shadowUpdateMode = pc.SHADOWUPDATE_REALTIME;
	            //this.light.light.vsmBlurSize = window.devicePixelRatio > 1 ? 12 : 6;
	            this.app.graphicsDevice.maxPixelRatio = window.devicePixelRatio;
	            this.entity.script.sao.enabled = true;
	            this.entity.script.sao.saoBlurEnabled = true;
	            break;
			default:
    	        this.light.light.castShadows = true;
	            //this.light.light.shadowUpdateMode = pc.SHADOWUPDATE_REALTIME;
	            //this.light.light.vsmBlurSize = window.devicePixelRatio > 1 ? 12 : 6;
	            this.app.graphicsDevice.maxPixelRatio = window.devicePixelRatio;
	            this.entity.script.sao.enabled = true;
	            this.entity.script.sao.saoBlurEnabled = true;
	            value = 4;
	           	break;
	    }
	   	//
	   	Utils.toggleClassByPrefix( document.body,'quality-' + value, 'quality-');
	}

    
	AdaptQuality(dt) {
	    
	      if (this.isDebugBuild) {
	        alert ("Current Average Framerate is: " + this.currentAverageFps);
	      }

	      // Decrease level if framerate too low
	      if ( this.currentAverageFps < this.LowerFPSThreshold ) {
	        --this.quality;
	        --this.stability;
	          
	        if ( !this.lastMovementWasDown ) {
	          ++this.flickering;
	        }
	        this.lastMovementWasDown = true;
	        if (this.isDebugBuild) {
	          alert ("Reducing Quality Level, now " + this.quality );
	        }

	        // In case we are "flickering" (switching between two quality settings),
	        // stop it, using the lower quality level.
	        if (this.flickering > 1) {
	          if (this.isDebugBuild) {
	            alert ( "Flickering detected, staying at " +  this.quality + " to stabilise." );
	          }
	          clearInterval( this.interval );
	        }
	          
	        if ( this.quality === 1 ) {
	          if (this.isDebugBuild) {
	            alert ( "Lowest quality, removing automatic adaptation. " );
	          }
	          clearInterval( this.interval );
	        }

	      } else  if (this.currentAverageFps > this.UpperFPSThreshold) {
	        // Increase level if framerate is too high
	        ++this.quality;
	        --this.stability;
	          
	        if ( this.lastMovementWasDown ) {
	          ++this.flickering;
	        }
	        this.lastMovementWasDown = false;
	        if ( this.isDebugBuild ) {
	          alert ("Increasing Quality Level, now " + this.quality );
	        }
	      } else {
	        ++this.stability;
	      }

	      // If we had a framerate in the range between 25 and 60 frames three times
	      // in a row, we consider this pretty stable and remove this script.
	      if (this.stability > 3) {
	        if (this.isDebugBuild) {
	          alert ("Framerate is stable now, removing automatic adaptation.");
	        }
	        clearInterval( this.interval );
	      }

	      // Reset moving average
	      this.numberOfDataPoints = 0;
	      this.currentAverageFps = 0;
	}

	// Print the number of drawcalls
	update(dt) {
	    this.updateCumulativeAverageFPS( 1 / dt );
	}


	updateCumulativeAverageFPS( newfps ) {
	    if ( !isFinite( newfps ) )
	        return;
	    
	    this.numberOfDataPoints++;
	    this.currentAverageFps += ( newfps - this.currentAverageFps) / this.numberOfDataPoints;
	}
};


Quality.attributes.add('quality', {
    type: 'number',
    enum: [
        { 'LQ': 1 },
        { 'SQ': 2 },
        { 'MQ': 3 },
        { 'HQ': 4 }
    ]
});

Quality.attributes.add('LowerFPSThreshold', { type: 'number', default: 20 });
Quality.attributes.add('UpperFPSThreshold', { type: 'number', default: 60 });
Quality.attributes.add('timeIntervalToAdaptQualitySettings', { type: 'number', default: 3 });

Quality.attributes.add('isDebugBuild', { type: 'boolean', default: false });