var LayoutView = require('./view/LayoutView');

var HomeController = require('./controller/HomeController'),
    HouseController = require('./controller/HouseController');

var currView;

module.exports = Backbone.Router.extend({
    controllers: {},

    initialize: function( options ){
        this.controllers.home = new HomeController({router: this});
        this.controllers.house = new HouseController({router: this});
        //
        if (!this.layoutView){
            this.layoutView = new LayoutView({});
            this.layoutView.render();
        }
        //Backbone.history.on('route', this.historyChange, this );    
    },

    routes: {
        '*notFound'                             : 'notFound'
    },

    historyChange: function( event ) {
        var url = Backbone.history.getFragment();           
        var host = Backbone.history.location.host;
        //
    }
});