import protoScript from "~/js/lib/ProtoScript";
import App from '~/js/App';
import Utils from '~/js/Utils';

var FadeByStyle = function() {
    this.name = 'fadeByStyle';
    //
    this.attributes = {
        'element': { type: "string", default:"" }
    };

	FadeByStyle.prototype.initialize = function() {
	    this.element = document.querySelector( "#" + this.element );
	    //add listeners
    	this.app.on('form:change', this.onAttributeChange, this );  
	};

	FadeByStyle.prototype.setData = function( data, model ) {
		//extend data to other - meshinstance materials
		for (var key in data ) {
			//get meshinstance for current extras, serialized by name
			var mi = model.meshInstances.filter( item => { return item.node.name === key; }); //array
			//meshinstance is null, it is a group
			if (mi.length === 0 ) mi = data[key].children;

			var fadeClassArray = []; //if is need more than one condition to one object
			data[key].fadeClass.split('||').forEach( ( fadeClass, index ) => {
				//if is needed different dom element then ID ie:body
				var diffElement = fadeClass.split('|');
				if ( diffElement.length ) {
					data[key].element = document.querySelector( diffElement[0] );	
					fadeClassArray.push( fadeClass.replace(diffElement[0] + "|",'').split(',') );		
				} else {
					fadeClassArray.push( fadeClass.split(',') );
				}
			});
			data[key].fadeClass = fadeClassArray;
			//
			data[key].fadeChildren = JSON.parse( data[key].fadeChildren.toLowerCase() );
			data[key].fadeTime = parseFloat( data[key].fadeTime.replace(/,/, '.') );
			data[key].fadeCondition = data[key].fadeCondition.toLowerCase();
			data[key].isFade = (key)=> { 
				return data[key].fadeClass.some( (pel) => {
					return pel[ data[key].fadeCondition ]( (el) => { 
						return Utils.hasClass( data[key].element ? data[key].element : this.element, el ) === true;
					});
				});
			};

			//
			if ( data[key].fadeChildren ) {
				this.temp = [];				
				this.grabChildrens( mi );
				//
				var mii = model.meshInstances.filter(a => this.temp.some(b => a.node.name === b));
				data[key].meshInstances = mii;
	    		data[key].materials = this.setMaterial( mii, data[key].fadeTime ).map(a => a.material);
			} else {
				data[key].meshInstances = mi;
				data[key].materials = this.setMaterial( mi, data[key].fadeTime ).map(a => a.material);
			}
			//
		};
        this.data = data;
        this.onAttributeChange( 0.01 );
	};

	//handlers
	FadeByStyle.prototype.onAttributeChange = function( time, callback ) {
		var fin= [];
		var fout= [];
		for (var key in this.data ) {
			if ( this.data[key].isFade(key) )
				fin = fin.concat(...this.data[key].materials);
			else
				fout = fout.concat(...this.data[key].materials);
		}
		//remove fadeins materials which are contain in fadeouts
		fin = fin.filter(x => !fout.includes(x));
		//fout = fout.filter(x => !fin.includes(x));
		//console.log("fin", fin );
		//console.log("fout", fout );
		this.fadeOpacity( 'fadein', fin, time, callback );		
		this.fadeOpacity( 'fadeout', fout, time, callback );	
	};

	//tweening
	FadeByStyle.prototype.fadeOpacity = function( fadeDirection, materials, time, callback) {
	    materials.forEach( ( material, i ) => {
	    	if (material === undefined ) return;
    	    var data = { opacity: material.opacity };
	        var toOpacity = ( fadeDirection === 'fadein' ? ( material.hasOwnProperty('opacity_stored') ? material.opacity_stored : 1 ) : 0 ); //check for stored opacity
	        time = time === undefined ? material.fadeTime : time ; //
	        //
	       	material.blendType = pc.BLEND_PREMULTIPLIED;//pc.BLEND_NORMAL;
	       	//
        	/*if ( fadeDirection === 'fadein' )
            	material.depthWrite = true;
            else
				material.depthWrite = false; */

	        if ( fadeDirection === 'fadein' )
	            material.meshInstances.forEach( ( mi ) => { mi.visible = true; });

	       this.entity
	        .tween( data )
	        .to( { opacity: toOpacity } , time, pc.QuarticInOut )
	        .on('update', function () {
	            material.opacity = data.opacity;
	            material.update(); // update material
	        })
	        .on('complete', function () {     
	          	if ( !material.hasOwnProperty('opacity_stored') && fadeDirection === 'fadein' )
	            	material.blendType = pc.BLEND_NONE;
	            //
	            if ( fadeDirection === 'fadeout' )
	            	material.meshInstances.forEach( ( mi ) => { mi.visible = false; });
	            //
	            App.pc.updateShadow();
	            //
	           	if ( callback ) {
	           		callback();
	           		callback = null;
	           	}
	        })
	        .start();
	    });
	};	

	FadeByStyle.prototype.setFadeByState = function( state, callback ) {
		Utils.toggleClassByPrefix( document.body,'state-' + state,'state-');
		this.onAttributeChange(0.0001, callback);
	};

	//prepare materials for tweenning
	FadeByStyle.prototype.setMaterial = function( meshinstances, fadeTime ) {
	    meshinstances.forEach(( mi, index ) => {
	    	//if ( mi.hasOwnProperty('material') ) {

	    	if ( !mi.material.hasOwnProperty('cloned') ) {
		    	var mat = mi.material.clone();
		    	//due to right way to restore opacity after tweening
		    	if ( mat.opacity !== 1 )
		    	    mat.opacity_stored = mat.opacity;
		    	//
		    	mat.cloned = true;
		    	mat.fadeTime = fadeTime;
		    	//
		    	//mat.name = "_" + mat.name + index;
		        mi.material = mat;        
		        mat.update();   
	    	}

	    });
	    return meshinstances;
	};
	//recursive tool for getting childrens structure, mesh instances and graph nodes
	FadeByStyle.prototype.grabChildrens = function( mi ) {
	    if (mi === undefined ) return;
	    //
	    mi.forEach( ( child ) => {
	    	//hasOwnProperty('tags') - true graphNode, false meshinstance
    		child = !child.hasOwnProperty('tags') ? child.node : child;	
			this.temp.push( child.name );
	        this.grabChildrens( child.children );
	    });
	};
};
export default protoScript( FadeByStyle );
//