App = require("~/js/App");

module.exports = {

    isie11: function() {
      return !!window.MSInputMethodContext && !!document.documentMode;
    },

    isMobile: function() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    },

    isIos: function  () {
        return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    },

    getUrlParams: function (search) {
        let hashes = search.slice(search.indexOf('?') + 1).split('&');
        let params = {};
        hashes.map(hash => {
            let [key, val] = hash.split('=');
            params[key] = 1; //decodeURIComponent(val);
        });

        return params;
    },
    stripQueryStringAndHashFromPath: function (url) {
      return url.split("?")[0].split("#")[0];
    },

    //array
    arrFind: function( array, regex ) {
        /* var x = [ "banana", "apple", "orange" ];
        console.log(x.find(/na/)); // Contains 'na'? Outputs: [0]
        console.log(x.find(/a/)); // Contains 'a'? Outputs: [0,1,2]
        console.log(x.find(/^a/)); // Starts with 'a'? Outputs: [0]
        console.log(x.find(/e$/)); // Ends with 'e'? Outputs: [1,2]
        console.log(x.find(/pear/)); // Contains 'pear'? Outputs: [] */
        return array.reduce(function (acc, curr, index, arr) {
          if (regex.test(curr)) { acc.push(index); }
          return acc;
        }, []);
    },

    //transition
    getAnimationEndEventName: function() {
      var transitions = {
        "animation"      : "animationend",
        "OAnimation"     : "oAnimationEnd",
        "MozAnimation"   : "animationend",
        "WebkitAnimation": "webkitAnimationEnd"
       };
      let bodyStyle = document.body.style;
      for(let transition in transitions) {
          if(bodyStyle[transition] != undefined) {
              return transitions[transition];
          } 
      }
    },
    
    supportsTransitions: function() {
      var b = document.body || document.documentElement,
          s = b.style,
          p = 'transition';

      if (typeof s[p] == 'string') { return true; }

      // Tests for vendor specific prop
      var v = ['Moz', 'webkit', 'Webkit', 'Khtml', 'O', 'ms'];
      p = p.charAt(0).toUpperCase() + p.substr(1);

      for (var i=0; i<v.length; i++) {
          if (typeof s[v[i] + p] == 'string') { return true; }
      }

      return false;
    },

    //class
    addClass: function( el, className ) {
        if (el.classList)
          el.classList.add(className);
        else
          el.className += ' ' + className;
    },

    removeClass: function( el, className ) {
        if (el.classList)
          el.classList.remove(className);
        else
          el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
     },

    hasClass: function( el, className ) {
          if (el.classList)
              return el.classList.contains(className);
          else
              return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
     },

    containClass: function( el, className ) {
        return new RegExp('(^| )' + className, 'gi').test(el.className);
     },     

    toggleClass: function( el, className ) {
          if (el.classList) {
            el.classList.toggle(className);
          } else {
            var classes = el.className.split(' ');
            var existingIndex = classes.indexOf(className);

            if (existingIndex >= 0)
              classes.splice(existingIndex, 1);
            else
              classes.push(className);

            el.className = classes.join(' ');
          }
     },

    getClassByPrefix: function(el, prefix) {
        var regx = new RegExp('\\b(\\w*' + prefix + '\\w*)\\b', 'g');
        return el.className.match(regx);
    },

    toggleClassByPrefix: function(el, className, prefix ) {
        var regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g');
        el.className = el.className.replace(regx, '');
        this.addClass(el, className);
    },

    getDOMClasslist: function( domSearch, domTarget, formElement ) {
        var that = this;
        //
        Array.prototype.forEach.call( domSearch.querySelectorAll( formElement ), function( el, j ){
          if ( el.checked) {
            that.toggleClassByPrefix( domTarget, el.name + '-' + el.id, el.name + '-' );
            //
            if ( el.getAttribute('addToClass') ) {
              var oldClass = that.getClassByPrefix( domTarget, el.getAttribute('addToClass') );
              that.toggleClassByPrefix( domTarget, oldClass + '-' + el.id, el.getAttribute('addToClass') );
            } 
          }
          //
        });
    },    

    //string
    capitalize: function ( string ) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },


    getParameterByName: function(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    //literal template fill variable
    fillTemplate: function( templateString, templateVars ) {
        console.log(  );
        if ( this.isie11() )
          return templateString;
        else
          return new Function("return `" + templateString + "`;").call( templateVars );
    },

    //change url segment 
    getURLpart: function( url, index ) {    
        url = url.replace( App.HOME_DIR, '').split("/");
        return url[ index ];
    },

    getURLsegLen: function() {
      return Backbone.history.getFragment().replace( App.HOME_DIR, '').split('/').length;         
    },

    changeURLpart: function( url, index, value, count ) {    
        var _count = count || 1;
        //url window.location.pathname
        url = url.replace(App.HOME_DIR, '').split("/");
        url[ index ] = value; //add id to URL
        if ( value === '' ) url.splice( index, _count );
        return App.HOME_DIR.replace('/','') + url.join("/");
    },

    getUrlExtension: function( url ) {
        // Extension starts after the first dot after the last slash
        var extStart = url.indexOf('.',url.lastIndexOf('/')+1);
        if (extStart==-1) return false;
        var ext = url.substr(extStart+1),
        // end of extension must be one of: end-of-string or question-mark or hash-mark
        extEnd = ext.search(/$|[?#]/);
        return ext.substring (0,extEnd);
    },

    //copy-clone object whithout references, specially backbone models
     deepCopy:function(object) {
        const cache = new WeakMap(); // Map of old - new references

        function copy(obj) {
            if (typeof obj !== 'object' ||
                obj === null ||
                obj instanceof HTMLElement
            )
                return obj; // primitive value or HTMLElement

            if (obj instanceof Date) 
                return new Date().setTime(obj.getTime());

            if (obj instanceof RegExp) 
                return new RegExp(obj.source, obj.flags);

            if (cache.has(obj)) 
                return cache.get(obj);

            const result = obj instanceof Array ? [] : {};

            cache.set(obj, result); // store reference to object before the recursive starts

            if (obj instanceof Array) {
                for(const o of obj) {
                     result.push(copy(o));
                }
                return result;
            }

            const keys = Object.keys(obj); 

            for (const key of keys)
                result[key] = copy(obj[key]);

            return result;
        }

        return copy(object);
    },

    hexToRgb: function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16) / 255,
        g: parseInt(result[2], 16) / 255,
        b: parseInt(result[3], 16) / 255
      } : null;
    },

    isHex: function( hex ) {
        var a = parseInt(hex,16);
        return (a.toString(16) === hex.toLowerCase() && hex.length === 6 );
    },

    //add script to header
    loadScript: function (src) {
      var head = document.getElementsByTagName('head')[0];
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      return new Promise(function (resolve) {
          script.onload = resolve;
          head.appendChild(script);
      });
    },

    tryParseJSON: function (jsonString) {
        try {
            var o = JSON.parse(jsonString);
            if (o && typeof o === "object")
              return o;
        }
        catch (e) { }
        return false;
    },

    //pc tools
    //vec
    stringToVec2: function( arg ) {
      return new pc.Vec2( arg.split(',').map(Number) );
    },
    stringToVec3: function( arg ) {
      return new pc.Vec3( arg.split(',').map(Number) );
    }   

};