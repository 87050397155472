import protoScript from "~/js/lib/ProtoScript";
import Utils from '~/js/Utils';

var TransByStyle = function(){
    this.name = 'transByStyle';
    //
    this.attributes = {
        'element': { type: "string", default:"" }
    };

	TransByStyle.prototype.initialize = function() {
	    this.element = document.querySelector( "#" + this.element );
	    //add listeners
	    this.observer = new MutationObserver( this.onMutationObserve.bind(this) );
	    this.observer.observe( this.element, { attributes: true });	  	
	};

	TransByStyle.prototype.setData = function( data, model ) {
		this.data = [];
		for (var key in data ) {
			var mi = model.meshInstances.filter( item => { return item.node.name === key; }); 
			//I need transform non meshinstances entity (cameras), so its hack
			if ( !mi.length)
				mi = model.graph.children.filter( item => { return item.name === key; }); 
			//
			data[ key ].forEach( ( item, i ) => {
				if ( item.transPos !== "0,0,0" || item.transSca !== "0,0,0" || item.transRot !== "0,0,0") {
					var obj = {};
					obj.class = item.class.split(',');
					obj.condition = item.condition.toLowerCase();
					obj.isTrans = ( key )=> { 
						return this.data[key].class[ this.data[key].condition ]( (el) => { return Utils.hasClass( this.element, el ) === true; });
					};
					obj.meshInstances = mi;
					if ( item.transPos !== "0,0,0" ) {
						//hasOwnProperty('tags') - true graphNode, false meshinstance

						obj.savePos = ( !mi[0].hasOwnProperty('tags') ? mi[0].node.getLocalPosition().clone() : mi[0].getLocalPosition().clone() );
						var subRes = new pc.Vec3();
						obj.transPos = subRes.sub2( obj.savePos, Utils.stringToVec3( item.transPos ) );
					}
					//
					if ( item.transSca !== "0,0,0" ) {
						obj.saveSca = ( !mi[0].hasOwnProperty('tags') ? mi[0].node.getLocalScale().clone() : mi[0].getLocalScale().clone() );
						subRes = new pc.Vec3();
						obj.transSca = subRes.mul2( obj.saveSca, Utils.stringToVec3( item.transSca ) );
					}
					//
					if ( item.transRot !== "0,0,0" ) {
						obj.saveRot = ( !mi[0].hasOwnProperty('tags') ? mi[0].node.getLocalEulerAngles().clone() : mi[0].getLocalEulerAngles().clone() );
						obj.transRot = Utils.stringToVec3( item.transRot );
					}

					//obj.transRot = Utils.stringToVec3( item.transRot );
					obj.transTime = parseFloat( item.transTime.replace(/,/, '.') );
					this.data[ key + "_" + i ] = obj;
				}
			});

		};
		this.onAttributeChange(0.01 );		
	};

	//handlers
	TransByStyle.prototype.onMutationObserve = function( mutations ) {
		mutations.forEach((mutation) => {
			if ( mutation.type == "attributes" )
				this.onAttributeChange();
		});
	};
	TransByStyle.prototype.onAttributeChange = function( time ) {
		for (var key in this.data ) {
			if ( this.data[key].isTrans(key) ) {
				this.tweenTrans( 'fadein', time, this.data[key] );
			} else {
				this.tweenTrans( 'fadeout', time, this.data[key] );
			}
		}
	};


	//tweening
	TransByStyle.prototype.tweenTrans = function( direction, time, data ) {

	    data.meshInstances.forEach( ( mi, i ) => {
	    	mi = ( !mi.hasOwnProperty('tags') ? mi.node : mi );
	    	///
	    	//
			var transDataPos = mi.getLocalPosition();
	        var transDataPosTo = ( direction === 'fadein' ? data.transPos : data.savePos );
			
			var transDataSca = mi.getLocalScale();
	        var transDataScaTo = ( direction === 'fadein' ? data.transSca : data.saveSca );

			var transDataRot = mi.getLocalEulerAngles();
	        var transDataRotTo = ( direction === 'fadein' ? data.transRot : data.saveRot );	        

	    	if ( mi.name.includes("Camera") ) {
	    		//I use scale property for change ortoheight of camera
	    		mi.orthoHeight = transDataScaTo ? ( transDataScaTo.x !== 1 ? transDataScaTo.x : 8 ) : 8;
	    	}	        

	        time = time === undefined ? data.transTime : time ;
			//
			this.entity
			.tween( transDataPos )
			.to( transDataPosTo , time, pc.QuarticInOut )
			.on('update', function () {
				mi.setLocalPosition( transDataPos );
			})
			.on('complete', function () {    

			})
			.start();
			//scale without tweening
			if ( transDataScaTo )
    			mi.setLocalScale( transDataScaTo );		

			//rotate without tweening
			if ( transDataRotTo )
    			mi.setLocalEulerAngles( transDataRotTo );		    		
	    });
	    //mesh.node.setPosition(matB.getTranslation());
    	//mesh.node.setEulerAngles(matB.getEulerAngles());
    	//mesh.node.setLocalScale(matB.getScale());
	};	

};
export default protoScript( TransByStyle );
//