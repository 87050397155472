var Utils = require("~/js/Utils");

module.exports = Backbone.Controller.extend({

	initialize: function() {
		this.view = null;
	},
	//
	directionStart: function() {
		this.setClass( this.view.el, 'start' );
	},
	directionSame: function() {
		this.setClass( this.view.el, 'same' );
	},
	directionUp: function() {
		this.setClass( this.view.el, 'up' );
	},
	directionDown: function() {
		this.setClass( this.view.el, 'down' );
	},

	setClass: function( el, className ) {
		//console.log('___dir ', className);		
		el.addEventListener( Utils.getAnimationEndEventName(), this.onAnimationEnd.bind(this) );
		//
		Utils.toggleClassByPrefix( el, 'dir-' + className, 'dir-'); 
	},

    onAnimationEnd: function( evt ) {
        if ( evt.animationName.includes('in') ) this.onAnimationEndIn();
    	if ( evt.animationName.includes('out') ) this.onAnimationEndOut();        
    },

    onAnimationEndIn: function() {},

    onAnimationEndOut: function() {}

});