var template = require('~/js/template/content/nested/house/floorSetTemplate.html'),
	App = require('~/js/App'),
	Utils = require('~/js/Utils');  

module.exports = Backbone.View.extend({
       
    id: 'floorSet',

    events: {
        "click div":  "onButtonClick"
    },     

    initialize: function( id ) {
        this.houseId = id;
        this.render();
    },

    render: function() {
        this.el.innerHTML = template( {} );        
        return this;
    },

    update: function( id ) {
        this.render();
    },

    onButtonClick: function( event ) {
    	var state = 'state-' + event.currentTarget.getAttribute('targetClass');
    	//
    	if ( Utils.hasClass( document.body, state ) )
    		state = 'state-all';
    	//
    	Utils.toggleClassByPrefix( document.body, state, 'state-');
	    if ( App.hasOwnProperty('pc') )
	    	App.pc.app.fire('form:change'); //, { group: group }    	
    },    

    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }
});