module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="typeofview">\r\n    <input type="radio" id="catalog" value="catalog" name="projectview" ';
 if ( typeofview === "catalog" ) { 
__p+='checked="checked"';
 } 
__p+='><label for="catalog">katalog</label>\r\n    <input type="radio" id="saved" value="saved" name="projectview" ';
 if ( typeofview === "saved" ) { 
__p+='checked="checked"';
 } 
__p+='><label for="saved">uložené</label>\r\n</div>\r\n\r\n<main>\r\n';
 if ( typeofview === "catalog" ) { 
__p+='\r\n    ';
 for(var key in data) { 
__p+='\r\n        <article>\r\n            <a href="'+
((__t=( data[key]({}).url ))==null?'':__t)+
'">\r\n                <img src="/public/img/houses/'+
((__t=( data[key]({}).file ))==null?'':__t)+
'">\r\n                <div class="text">\r\n                    <h2>'+
((__t=( data[key]({}).title_short ))==null?'':__t)+
'</h2>\r\n                </div>\r\n            </a>\r\n        </article>\r\n    ';
 }; 
__p+='\r\n';
 } else { 
__p+='    \r\n\r\n    ';
 for(var key in data) { 
__p+='\r\n        <article>\r\n            <svg viewbox="0 0 128 128" class="svg" hash="'+
((__t=( data[key].get("hash") ))==null?'':__t)+
'"><use xlink:href="#ico-trash"></use></svg>              \r\n            <a href="'+
((__t=( data[key].get("id") ))==null?'':__t)+
'/'+
((__t=( data[key].get("hash") ))==null?'':__t)+
'">\r\n                <div class="img" style="background-image: url(\''+
((__t=( App.API.slice(0, -3) + "/images/" + data[key].get("owner") + "/" + data[key].get("image") ))==null?'':__t)+
'\')"></div>\r\n                <div class="text">\r\n                    <h2>'+
((__t=( data[key].get("klient") ))==null?'':__t)+
'<span>'+
((__t=( data[key].get("datum") ))==null?'':__t)+
'</span></h2>\r\n                </div>\r\n            </a>\r\n        </article>\r\n    ';
 }; 
__p+='\r\n';
 } 
__p+='    \r\n</main>';
}
return __p;
};
