var projectTemplate = require('~/js/template/layout/popup/projectTemplate.html'),
    saveTemplate = require('~/js/template/layout/popup/saveTemplate.html'),
    deleteTemplate = require('~/js/template/layout/popup/deleteTemplate.html'),
    App = require("~/js/App"),
    Utils = require('~/js/Utils');

module.exports = Backbone.View.extend({ 

    id: 'popup',

    className: 'popup-overlay',

    events: {
        "click .close-icon":  "onClose",
        "click button.no":  "onButtonNo",
        "click button.yes":  "onButtonYes",
        "focus input":          "onInputFocus",
        "blur input":          "onInputBlur",        
    },   

    initialize: function () {
    },

    render: function() {
        return this;
    },


    show: function( templateName, data, callback ) {
        this.el.innerHTML = eval(templateName)({ data: data});
        //
        Utils.addClass( this.el, 'show' );
        this.callback = callback;
    },

    hide: function() {
        Utils.removeClass( this.el, 'show' );
    },

    onClose: function( evt ) {
        this.hide();
        this.callback(null);        
    },

    onButtonNo: function( evt ) {
        this.hide();
        this.callback(null);
    },

    onButtonYes: function( evt ) {
        var error;
        //
        this.el.querySelectorAll('input,select').forEach( (el) =>{
            if ( el.getAttribute('required') !== null ) {
                if ( el.value === "" ) {
                    error = true;
                    el.style.borderColor = "#842322";
                } else {
                    el.style.borderColor = "#1A2E46";
                }

            };
        });
        //
        if ( !error ) {
            this.hide();        
            this.callback( this.el );
        }
    },

    onInputFocus: function( evt ) {
        if ( evt.target.getAttribute('focusvalue') && evt.target.value === "" )
            evt.target.value =  evt.target.getAttribute('focusvalue');
    },

    onInputBlur: function( evt ) {    
        if ( evt.target.value !== "" )
            evt.target.style.borderColor = "#1A2E46";        
    },

    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }

});
