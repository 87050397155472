module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="popup">\r\n	<div id="close" class="close-icon" ><span class="closeicon"></span></div>\r\n	<h1>Uložení a sdílení projektu</h1>\r\n	\r\n	<fieldset>\r\n		<input type="text" id="klient" name="klient" value="'+
((__t=( data.klient ))==null?'':__t)+
'" placeholder="Jméno a příjmení" required />\r\n		<input type="text" id="email" name="email" value="'+
((__t=( data.email ))==null?'':__t)+
'" placeholder="Email" required />\r\n		<input type="text" id="datum" name="datum" value="'+
((__t=( data.datum ))==null?'':__t)+
'" placeholder="Datum" focusvalue="'+
((__t=( data.focusdatum ))==null?'':__t)+
'" required/>\r\n	</fieldset>\r\n	<div class=\'buttons\'>\r\n		<button type=\'button\' class=\'no text border\'>zrušit</button>\r\n		<button type=\'button\' class=\'yes text border\'>potvrdit</button>\r\n	</div>\r\n</div>\r\n';
}
return __p;
};
