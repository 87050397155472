var ProjectModel = require('~/js/model/ProjectModel'),
    template = require('~/js/template/content/nested/house/saveTemplate.html'),
	App = require('~/js/App'),
    data = require('~/js/Data'),
    Utils = require('~/js/Utils');  

module.exports = Backbone.View.extend({
       
    id: 'save',

    events: {
        "click":  "onButtonClick"
    },      

    initialize: function( id, projectModel ) {
        this.houseId = id;
        this.projectModel = projectModel;
        this.render();
    },

    render: function() {
        this.el.innerHTML = template( {} );        
        return this;
    },

    update: function( id, projectModel ) {
        this.houseId = id;
        this.projectModel = projectModel;        
    },

    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }, 

    onButtonClick: function( event ) {
        var obj = {};
        obj.klient = this.projectModel.get('klient');
        obj.email = this.projectModel.get('email');
        obj.datum = this.projectModel.get('datum') || new Date().toLocaleDateString();
        //
        App.popUp.show( 'saveTemplate', obj,
        ( popupEl ) => {
            if ( popupEl ) {
                App.loader.show();
                //
                var klient = popupEl.querySelector( 'input#klient' ).value;
                var email = popupEl.querySelector( 'input#email' ).value;
                var datum = popupEl.querySelector( 'input#datum' ).value;
                //
                App.pc.takeScreen( this.houseId, ( image ) => {
                    //
                    this.projectModel.set('id', this.houseId);
                    this.projectModel.set('css', document.getElementById( this.houseId ).classList );
                    this.projectModel.set('owner', App.user.get('id'));                        
                    this.projectModel.set('klient', klient);
                    this.projectModel.set('email', email);
                    this.projectModel.set('datum', datum);
                    this.projectModel.set('image', image[0]);
                    this.projectModel.set('emaildata', {
                        email: email,
                        cc: App.user.get('email'),
                        subject: this.houseId.toUpperCase() + " dům Canaba",
                        text:   [
                                "Vážená paní, Vážený pane, " + klient + ",<br><br>",
                                "Děkujeme Vám za návštěvu Centra vzorových domů.",
                                "Na této <a href='https://planner.canaba.cz/" + this.houseId + "/${hash}'>adrese</a> zasíláme model provedení Vašeho rodinného domu <b>" + this.houseId.toUpperCase() + "</b>.<br>",
                                "Vážíme si Vaší důvěry a věříme, že se stanete jedním z tisícovek našich spokojených klientů, kterým jsme za více než 30 let naší činnosti pomohli zrealizovat sen o kvalitním, úsporném a zdravém bydlení.",
                                "Děkujeme, Vaše Canaba<br><br>",
                                "<b>CANABA a.s.</b><br>",
                                "Štětkova 1001/5<br>",
                                "140 00 Praha 4<br>",
                                "Czech Republic<br>",
                                "tel.: +420 234 096 234<br>",
                                "E-mail: canaba@canaba.cz<br>",
                                "Web: www.canaba.cz<br><br>",                          
                                "<img src='cid:logo' /><br>"                                
                                ].join("\n")
                    });
                    //
                    this.projectModel.save({}, {
                        success: (model, respose, options) => {
                            App.loader.hide();
                            Backbone.history.navigate( Utils.getURLpart(window.location.pathname, 1) + "/" + respose.hash, true );
                        },
                        error: (model, xhr, options) => {}
                    });
                });
            }
        });
    }
});