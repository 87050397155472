import * as pc from "playcanvas";

class SAOEffect extends pc.PostEffect {
    constructor(graphicsDevice, entity) {
        super(graphicsDevice);
        //
        this.resolution = [0, 0];
        this.matrix = new pc.Mat4();
        this.matrixPrevious = new pc.Mat4();
        this.entity = entity;
        //
        this.needsDepthBuffer = true;
        //
        this.ssaoShader = new pc.Shader(graphicsDevice, {
            attributes: {
                aPosition: pc.SEMANTIC_POSITION
            },
            vshader: [
                (graphicsDevice.webgl2) ? ("#version 300 es\n\n" + pc.shaderChunks.gles3VS) : "",
                "attribute vec2 aPosition;",
                "",
                "varying vec2 vUv0;",
                "",
                "void main(void)",
                "{",
                "    gl_Position = vec4(aPosition, 0.0, 1.0);",
                "    vUv0 = (aPosition.xy + 1.0) * 0.5;",
                "}"
            ].join("\n"),
            fshader: [
                (graphicsDevice.webgl2) ? ("#version 300 es\n\n" + pc.shaderChunks.gles3PS) : "",
                "precision " + graphicsDevice.precision + " float;",
                (graphicsDevice.extStandardDerivatives && !graphicsDevice.webgl2) ? ("#extension GL_OES_standard_derivatives : enable") : "",
                pc.shaderChunks.screenDepthPS,
                "",
                "#define SAOSAMPLES 11",
                "",
                "uniform float saoSamples;",
                "uniform float saoIntensity;",
                "uniform float saoScale;",
                "uniform float saoBias;",
                "uniform float saoKernelRadius;",
                "uniform float saoRangeThreshold;",
                "uniform float saoRangeFalloff;",
                "",
                "#define PI 3.14159265359",
                "#define PI2 6.28318530718 ",     
                "#define EPSILON 1e-6",
                "",
                "uniform sampler2D uColorBuffer;",
                "uniform float cameraNear;",
                "uniform float cameraFar;",
                "uniform vec3 view_position;",
                "uniform mat4 matrix_viewProjectionInverse;",
                "uniform vec2 resolution;",
                "",
                "varying vec2 vUv0;",
                "",
                "float getLinearScreenDepthh(vec2 uv) {",
                "  #ifdef GL2",
                "      #ifdef ES3",
                "          return linearizeDepth(texture(uDepthMap, uv).r) * camera_params.y * 0.01;",
                "      #else",
                "          return linearizeDepth(texture2D(uDepthMap, uv).r) * camera_params.y * 0.01;",
                "      #endif",
                "  #else",
                "      return unpackFloat(texture2D(uDepthMap, uv)) * camera_params.y * 0.01;",
                "  #endif",
                "}",
                "",
                "float pow2( float x ) { return x*x; }",
                "",
                "highp float rand( vec2 uv ) {",
                "    const highp float a = 12.9898, b = 78.233, c = 43758.5453;",
                "    highp float dt = dot( uv.xy, vec2( a,b ) ), sn = mod( dt, PI );",
                "    return fract(sin(sn) * c);",
                "}",
                "",
                "float getViewZ( const in float depth, const in float near, const in float far ) {",
                "    return ( near * far ) / ( ( far - near ) * depth - far );",
                "}",
                "",
                "vec3 getViewPositionFromDepth( const in vec2 screenPosition, const in float depth, const in float viewZ, const in mat4 projection, const in mat4 projectionInverse ) {",
                "    float clipW = projection[2][3] * viewZ + projection[3][3];",
                "    vec4 clipPosition = vec4( ( vec3( screenPosition * 2.0 - 1.0, depth ) - 0.5 ) * 2.0, 1.0 );",
                "    clipPosition *= clipW;",
                "    return ( projectionInverse * clipPosition ).xyz;",
                "}",
                "",
                "vec3 getViewPositionFromDepth( const in vec2 screenPosition, const in float depth) {",
                "    vec2 uv2  = screenPosition * 2.0 - vec2(1.0);",
                "    vec4 temp = matrix_viewProjectionInverse * vec4(uv2, -1.0, 1.0);",
                "    vec3 cameraFarPlaneWS = (temp / temp.w).xyz;",
                "",
                "    vec3 cameraToPositionRay = normalize(cameraFarPlaneWS - view_position);",
                "    vec3 originWS = cameraToPositionRay * depth + view_position;",
                "    vec3 originVS = (matrix_view * vec4(originWS, 1.0)).xyz;",
                "    return originVS;",
                "}",
                "",
                "#define NUM_RINGS 7",
                "",
                "vec3 getViewNormal( const in vec3 viewPosition ){",
                "    return normalize( cross( dFdx( viewPosition ), dFdy( viewPosition ) ) );",
                "}",
                "",
                "float getOcclusion( const in vec3 centerViewPosition, const in vec3 centerViewNormal, const in vec3 sampleViewPosition ) {",
                "    vec3 viewDelta = sampleViewPosition - centerViewPosition;",
                "    float viewDistance = length( viewDelta ) * saoScale;",
                "    return max(0.0, (dot(centerViewNormal, viewDelta) ) / viewDistance - saoBias) / (1.0 + pow2( viewDistance ) );",
                "}",
                "",
                "const float ANGLE_STEP = PI2 * float( NUM_RINGS ) / float( SAOSAMPLES );",
                "const float INV_SAOSAMPLES = 1.0 / float( SAOSAMPLES );",
                "",
                "float getAmbientOcclusion( const in vec3 centerViewPosition, const in vec3 centerViewNormal, const in float depth ) {",
                "    float angle = rand( vUv0 ) * PI2;",
                "    vec2 radius = vec2( saoKernelRadius * INV_SAOSAMPLES ) / resolution;",
                "    vec2 radiusStep = radius;",
                "    float occlusionSum = 0.0;",
                "    float weightSum = 0.0;",
                "",
                "    vec2 proximityCutoff = vec2(saoRangeThreshold, min(saoRangeThreshold + saoRangeFalloff, 1.0 - 1e-6));",
                "",
                "    for( int i = 0; i < SAOSAMPLES; i ++ ) {",
                "       vec2 sampleUv = vUv0 + vec2( cos( angle ), sin( angle ) ) * radius;",
                "        radius += radiusStep;",
                "        angle += ANGLE_STEP;",
                "",
                "        float sampleDepth = getLinearScreenDepthh( sampleUv );",
                "        if( sampleDepth >= ( 1.0 - EPSILON ) ) {",
                "            continue;",
                "        }",
                "",
                "        float proximity = abs(depth - sampleDepth);",
                "        vec3 sampleViewPosition = getViewPositionFromDepth( sampleUv, sampleDepth );",
                "        float falloff = 1.0 - smoothstep(proximityCutoff.x, proximityCutoff.y, proximity);",
                "        occlusionSum += getOcclusion( centerViewPosition, centerViewNormal, sampleViewPosition ) * falloff;",
                "        weightSum += 1.0;",
                "    }",
                "",
                "    if( weightSum == 0.0 ) return 0.0;",
                "    return occlusionSum * ( saoIntensity / weightSum );",
                "}",
                "",
                "",
                "void main() {",
                "   float linearDepth = getLinearScreenDepthh( vUv0 );",
                "   float ambientOcclusion = 1.0;",
                "   vec3 viewPosition = getViewPositionFromDepth( vUv0, linearDepth );",
                "   vec3 viewNormal = getViewNormal( viewPosition );",
                "   ambientOcclusion -= getAmbientOcclusion( viewPosition, viewNormal, linearDepth );",
                "",
                "   gl_FragColor = vec4( vec3(ambientOcclusion), 1.0);",
                "}",
            ].join("\n")
        });

        this.blurShader = new pc.Shader(graphicsDevice, {
            attributes: {
                aPosition: pc.SEMANTIC_POSITION
            },
            vshader: [
                (graphicsDevice.webgl2) ? ("#version 300 es\n\n" + pc.shaderChunks.gles3VS) : "",
                "attribute vec2 aPosition;",
                "",
                "varying vec2 vUv0;",
                "",
                "void main(void)",
                "{",
                "    gl_Position = vec4(aPosition, 0.0, 1.0);",
                "    vUv0 = (aPosition.xy + 1.0) * 0.5;",
                "}"
            ].join("\n"),
            fshader: [
                (graphicsDevice.webgl2) ? ("#version 300 es\n\n" + pc.shaderChunks.gles3PS) : "",
                "precision " + graphicsDevice.precision + " float;",
                (graphicsDevice.extStandardDerivatives && !graphicsDevice.webgl2) ? ("#extension GL_OES_standard_derivatives : enable") : "",
                pc.shaderChunks.screenDepthPS,
                "uniform sampler2D uColorBuffer;",
                "uniform sampler2D uSSAOBuffer;",  
                "uniform vec2 resolution;",                
                "uniform float saoBlurEnabled;",                  
                "uniform float saoBlurRadius;",                
                "uniform float saoDebug;",
                "varying vec2 vUv0;",
                "",
                "float getLinearScreenDepthh(vec2 uv) {",
                "  #ifdef GL2",
                "      #ifdef ES3",
                "          return linearizeDepth(texture(uDepthMap, uv).r) * camera_params.y * 0.01;",
                "      #else",
                "          return linearizeDepth(texture2D(uDepthMap, uv).r) * camera_params.y * 0.01;",
                "      #endif",
                "  #else",
                "      return unpackFloat(texture2D(uDepthMap, uv)) * camera_params.y * 0.01;",
                "  #endif",
                "}",
                "",
                "#define EDGE_SHARPNESS 0.5",
                "float blurAO(vec2 screenSpaceOrigin) {",
                "    float sum =  texture2D(uSSAOBuffer, screenSpaceOrigin).x;",
                "    float originDepth = getLinearScreenDepthh(screenSpaceOrigin);",
                "",
                "    float totalWeight = 1.0;",
                "    sum *= totalWeight;",
                "",
                "    for (int x = -4; x <= 4; x++) {",
                "      for (int y = -4; y <= 4; y++) {",
                "          if (x != 0 || y != 0) {",
                "              vec2 samplePosition = screenSpaceOrigin + vec2(float(x), float(y)) * vec2(1.0/resolution.x, 1.0/resolution.y) * saoBlurRadius;",
                "              float ao = texture2D(uSSAOBuffer, samplePosition).x;",
                "              float sampleDepth = getLinearScreenDepthh( samplePosition );",
                "              int kx = 4 - (x < 0 ? -x : x);",
                "              int ky = 4 - (y < 0 ? -y : y);",
                "              float weight = 0.3 + (abs(float(x * y)) / (25.0 * 25.0));",
                "              weight *= max(0.0, 1.0 - (EDGE_SHARPNESS * 2000.0) * abs(sampleDepth - originDepth));",
                "              sum += ao * weight;",
                "              totalWeight += weight;",
                "          }",
                "      }",
                "    }",
                "    const float epsilon = 0.0001;",
                "    return sum / (totalWeight + epsilon);",
                "}",
                "",
                "void main() {",
                "   vec4 inCol = texture2D( uColorBuffer,  vUv0 );",
                "   float ssao;",
                "   if (saoBlurEnabled == 0.0) {",                
                "       ssao = texture2D( uSSAOBuffer,  vUv0 ).r;",                
                "    } else {",
                "       ssao = blurAO( vUv0 );",                
                "    }",
                "",
                "   if (saoDebug == 0.0) {",
                "        gl_FragColor = vec4(inCol.rgb * ssao, 1.0);",
                "    } else {",
                "        gl_FragColor = vec4( vec3(ssao), 1.0);",
                "    }",
                "}",
            ].join("\n")
        });

        // Render targets
        var width = graphicsDevice.width;
        var height = graphicsDevice.height;
        var colorBuffer = new pc.Texture(graphicsDevice, {
            format: pc.PIXELFORMAT_R8_G8_B8_A8,
            width: width,
            height: height,
            mipmaps: false
        });
        colorBuffer.minFilter = pc.FILTER_LINEAR;
        colorBuffer.magFilter = pc.FILTER_LINEAR;
        colorBuffer.addressU = pc.ADDRESS_CLAMP_TO_EDGE;
        colorBuffer.addressV = pc.ADDRESS_CLAMP_TO_EDGE;
        colorBuffer.name = 'ssao';
        this.target = new pc.RenderTarget({ colorBuffer: colorBuffer, depth: true });
    }

    render(inputTarget, outputTarget, rect) {
        var device = this.device;
        var scope = device.scope;

        // Set the input render target to the shader. This is the image rendered from our camera
        scope.resolve("uColorBuffer").setValue(inputTarget.colorBuffer);
        const matrix = scope.resolve("matrix_viewProjection").getValue();
        this.matrix.set(matrix);
        this.matrix.invert();
        scope.resolve("matrix_viewProjectionInverse").setValue(this.matrix.data);
        this.resolution[0] = device.width * this.saoResolutionScale;
        this.resolution[1] = device.height * this.saoResolutionScale;
        scope.resolve("resolution").setValue(this.resolution);
        scope.resolve("saoSamples").setValue( this.saoSamples );             
        scope.resolve("saoIntensity").setValue( this.saoIntensity );
        scope.resolve("saoScale").setValue( this.saoScale );
        scope.resolve("saoBias").setValue( this.saoBias );
        scope.resolve("saoKernelRadius").setValue( this.saoKernelRadius );
        scope.resolve("saoRangeThreshold").setValue( this.saoRangeThreshold );
        scope.resolve("saoRangeFalloff").setValue( this.saoRangeFalloff );
        scope.resolve("saoBlurRadius").setValue( this.saoBlurRadius );
        scope.resolve("saoBlurEnabled").setValue( this.saoBlurEnabled );
        scope.resolve("saoDebug").setValue( this.saoDebug );

        pc.drawFullscreenQuad(device, this.target, this.vertexBuffer, this.ssaoShader, rect);

        scope.resolve("uSSAOBuffer").setValue(this.target.colorBuffer);
        pc.drawFullscreenQuad(device, outputTarget, this.vertexBuffer, this.blurShader, rect);        
    }
}

export default class SAO extends pc.ScriptType{
    initialize() {
        this.effect = new SAOEffect(this.app.graphicsDevice, this.entity);

        this.effect.saoResolutionScale = this.saoResolutionScale;
        this.effect.saoSamples = this.saoSamples;
        this.effect.saoIntensity = this.saoIntensity;
        this.effect.saoScale = this.saoScale;
        this.effect.saoBias = this.saoBias;
        this.effect.saoKernelRadius = this.saoKernelRadius;
        this.effect.saoRangeThreshold = this.saoRangeThreshold;
        this.effect.saoRangeFalloff = this.saoRangeFalloff;
        this.effect.saoBlurRadius = this.saoBlurRadius;
        this.effect.saoBlurEnabled = this.saoBlurEnabled;        
        this.effect.saoDebug = this.saoDebug;

        var queue = this.entity.camera.postEffects;
        queue.addEffect(this.effect);
    
        this.on('state', function (enabled) {
            if (enabled) {
                queue.addEffect(this.effect);
            } else {
                queue.removeEffect(this.effect);
            }
        });
    
        this.on('destroy', function () {
            queue.removeEffect(this.effect);
        });

        this.on('attr:saoBlurEnabled', (value, prev) => {
            this.effect.saoBlurEnabled = value;  
        });
        //
        this.app.graphicsDevice.on('resizecanvas', this.resize, this);
    }

    resize() {
        // Render targets        
        var device = this.app.graphicsDevice;
        var width = Math.floor(device.width);
        var height = Math.floor(device.height);
        if (this.effect.target && this.effect.target.width === width && this.effect.target.height === height)
            return;

        if (this.effect.target)
            this.effect.target.destroy();

        var colorBuffer = new pc.Texture( device, {
            format: pc.PIXELFORMAT_R8_G8_B8_A8,
            width: width,
            height: height,
            mipmaps: false
        });
        colorBuffer.minFilter = pc.FILTER_LINEAR;
        colorBuffer.magFilter = pc.FILTER_LINEAR;
        colorBuffer.addressU = pc.ADDRESS_CLAMP_TO_EDGE;
        colorBuffer.addressV = pc.ADDRESS_CLAMP_TO_EDGE;
        colorBuffer.name = 'ssao';

        this.effect.target= new pc.RenderTarget({ colorBuffer: colorBuffer, depth: true });
        device.scope.resolve("uSSAOBuffer").setValue(this.effect.target.colorBuffer);
    }
};

//Used to scale down the resolution at which the effects will render compared to the screen resolution. Set it to 1 to render at the same resolution, 0.5 to render at half the resolution.
SAO.attributes.add( "saoResolutionScale", {  title: "Resolution Scale", type: "number", default: 1, min: 0.01, max: 1, precision: 2 } );
//The number of samples collected per pixel to calculate the total occlusion. Larger values provide better quality but can have a performance hit.
SAO.attributes.add( "saoSamples", {  title: "Samples", type: "number", default: 12, min: 1, max: 32, precision: 0 } );
//Determines how dark the calculated occlusion is rendered.
SAO.attributes.add( "saoIntensity", {  title: "Intensity", type: "number", default: 1.5, min: 0, max: 3, precision: 2 } );
//Determines how far from the world position of the pixel occlusion sample points will be collected.
SAO.attributes.add( "saoScale", {  title: "Scale", type: "number", default: 0.5, min: 0, max: 1, precision: 2 } );
//A ratio that discards any sample point that falls in that range used to decrease artifacts on edges. The value should be visually estimated depending on the rendered models.
SAO.attributes.add( "saoBias", {  title: "Bias", type: "number", default: 0.25, min: 0.01, max: 2, precision: 2 } );
//Determines the radius of the occlusion effect around a pixel.
SAO.attributes.add( "saoKernelRadius", {  title: "Kernel Radius", type: "number", default: 25, min: 1, max: 1e3, precision: 0 } );
//
SAO.attributes.add( "saoRangeThreshold", {  title: "Range Threshold", type: "number", default: 0.0015, min: 0, max: 0.01, precision: 4 } );
//
SAO.attributes.add( "saoRangeFalloff", {  title: "Range Falloff", type: "number", default: 0.01, min: 0, max: 0.1, precision: 2 } );
 //Blurs within the given radius the collected occlusion points to render a final smooth shadow.
SAO.attributes.add( "saoBlurRadius", {  title: "Blur Radius", type: "number", default: 0.75, min: 0.01, max: 10, precision: 2 } );
//
SAO.attributes.add( "saoBlurEnabled", { type: "number", default: 0, title: "Debug" } );
SAO.attributes.add( "saoDebug", { type: "number", default: 0, title: "Debug" } );