import '../scss/style.scss';

import 'whatwg-fetch'; //due the IE11 fetch
import './lib/backbone.controller';

console.time('App');
import App from "./App";

var LoaderView = require('./view/layout/LoaderView'),
    UserModel = require('./model/UserModel'),
    Router = require('./Router'),    
    Utils = require('./Utils');


var Playcanvas = require('~/js/pc/Playcanvas').default;

    $.ajaxSetup({ cache: false });          // force ajax call on all browsers , on iPad 304 error , headers: { 'X-Requested-With' : 'XMLHttpRequest' }


    // Just use GET and POST to support all browsers
    Backbone.emulateHTTP = false;  

    //this code preserve cookies on localhost
    var proxiedSync = Backbone.sync;
    Backbone.sync = function(method, model, options) {
        options || (options = {});
        if (!options.crossDomain)
            options.crossDomain = true;
        if (!options.xhrFields)
            options.xhrFields = {withCredentials:true};

        return proxiedSync(method, model, options);
    };

App.eventBus = _.extend({}, Backbone.Events); // Global event aggregator 

//preloader view
App.loader = new LoaderView();
document.body.appendChild( App.loader.el );
App.loader.show();

appInit();

function appInit() {
    Utils.addClass( document.body, 'mobile-' + Utils.isMobile() );
    window.addEventListener("resize", onWindowResize, false);      
    onWindowResize();
    //
    App.user = new UserModel({}); 
    App.router = new Router();
    App.user.checkAuth({
        complete: () => {    
            App.pc = new Playcanvas(() => {

                App.loader.hide();
                // HTML5 pushState for URLs without hashbangs
                var hasPushstate = !!(window.history && history.pushState);
                if ( hasPushstate )
                    Backbone.history.start({ pushState: true, root: '/' });
                else
                    Backbone.history.start();

            });   
        }
    });
};

function onWindowResize() {
    var width = window.innerWidth;
    var height = window.innerHeight;    
    //
    if ( width < 360 ) {
        Utils.toggleClassByPrefix( document.body, 'size-xss', 'size-');
    }        
    else if ( width > 360 && width < 480 ) {
        Utils.toggleClassByPrefix( document.body, 'size-xs', 'size-');
    }
    else if ( width > 480 && width < 544 ) {
        Utils.toggleClassByPrefix( document.body, 'size-sm', 'size-');
    }
    else if ( width > 544 && width < 768) {
        Utils.toggleClassByPrefix( document.body, 'size-md', 'size-');
    }
    else if ( width > 768 && width < 992) {
        Utils.toggleClassByPrefix( document.body, 'size-lg', 'size-');
    }
    else if ( width > 992 && width < 1200) {
        Utils.toggleClassByPrefix( document.body, 'size-xl', 'size-');
    }
    else if ( width > 1200) {
        Utils.toggleClassByPrefix( document.body, 'size-xll', 'size-');
    }
    //
    if( height > width && App.orientation !== 'portrait' ){
        Utils.toggleClassByPrefix( document.body, 'mode-portrait', 'mode-');
        App.orientation = 'portrait';        
        App.eventBus.trigger('orientationChange', 'portrait');
    }
    if ( height < width && App.orientation !== 'landscape' ) {
        Utils.toggleClassByPrefix( document.body, 'mode-landscape', 'mode-');
        App.orientation = 'landscape';  
        App.eventBus.trigger('orientationChange', 'landscape');
    }
}   