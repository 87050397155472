var ProjectCollection = require('~/js/collection/ProjectCollection'),
    data = require('~/js/Data'),
    UserView = require('./nested/UserView'),
    template = require('~/js/template/layout/homeTemplate.html'),
    App = require('~/js/App'),
    Utils = require('~/js/Utils');

module.exports = Backbone.View.extend({
       
    tagName: 'section',

    className: 'home',

    events: {
        "click article a":  "onArticleClick",
        "change .typeofview":   "onFormChange",        
        "click svg":  "onTrashClick"
    },    

    initialize: function() {
        this.userView = new UserView();
        //
        this.render( "catalog" );
    },

    render: function( typeofview ) {
        this.el.innerHTML = template( { typeofview: typeofview, data: ( typeofview === "catalog" ? data.house : this.collection.models )  } );        
        this.el.appendChild( this.userView.el );   
        return this;
    },

    getData: function ( callback ) {
        this.collection = new ProjectCollection();     
        this.collection.url = App.API + '/plannerAll/' + App.user.get('id');  
        this.collection.fetch({
            success:  ( res ) => {
                callback(arguments[1]);
            },
            error: (model, xhr, options) => {console.log("chyba");
            }
        });
    },    

    onArticleClick: function( evt ) {
        evt.preventDefault();
        var href = evt.currentTarget.getAttribute('href');
        Backbone.history.navigate( '/' + href, true );
    },   

    onFormChange: function( evt ){
        evt.preventDefault();
        var name = evt.target.name;
        var val = evt.target.value;
        //        
        if ( val === "saved"){
            this.getData( this.render.bind(this), val );
        } else {
            this.render( val );
        }
    },

    onTrashClick: function( evt ) {
        evt.preventDefault();    
        App.popUp.show( 'deleteTemplate', {},
            ( popupEl ) => {
                if ( popupEl ) {
                    App.loader.show();
                    //
                    this.collection.where({hash: evt.currentTarget.getAttribute("hash")})[0].destroy({
                        success: (model, respose, options) => {
                            console.log("succ");
                            App.loader.hide();
                            this.getData( this.render.bind(this), "saved" );
                        },
                        error: (model, xhr, options) => {
                            console.log("err");
                        }
                    });
                 }
            });
    },

    dispose: function() {
        this.userView.dispose();
        //
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }    

});