import protoScript from "~/js/lib/ProtoScript";
import Utils from '~/js/Utils';

var TakeScreens = function(){
    this.name = 'takeScreens';
};    
export default protoScript( TakeScreens );

TakeScreens.prototype.initialize = function() {
    this._cameraPointIndex = 0;
    this._triggerScreenshot = false;

    // Create a 512x512x24-bit render target with a depth buffer
    var colorBuffer = new pc.Texture(this.app.graphicsDevice, {
        width: 1024,
        height: 1024,
        format: pc.PIXELFORMAT_R8_G8_B8_A8,
        autoMipmap: true
    });
    var depthBuffer = new pc.Texture(this.app.graphicsDevice, {
        format: pc.PIXELFORMAT_DEPTHSTENCIL,
        width: 1024,
        height: 1024,
        mipmaps: false,
        addressU: pc.ADDRESS_CLAMP_TO_EDGE,
        addressV: pc.ADDRESS_CLAMP_TO_EDGE
    });
    colorBuffer.minFilter = pc.FILTER_LINEAR;
    colorBuffer.magFilter = pc.FILTER_LINEAR;

    this.renderTarget = new pc.RenderTarget({
        colorBuffer: colorBuffer,
        depthBuffer: depthBuffer,
        samples: 8 // Enable anti-alias 
    });

    //var layer = this.app.scene.layers.getLayerByName( 'ScreenShotLayer' );
    //layer.renderTarget = this.renderTarget;

    this.entity.camera.renderTarget = this.renderTarget;
 

    this.framebuffer = this.app.graphicsDevice.gl.createFramebuffer();
    this.pixels = new Uint8Array(colorBuffer.width * colorBuffer.height * 4);

    var cb = this.renderTarget.colorBuffer;
    this.canvas = window.document.createElement('canvas');
    this.context = this.canvas.getContext('2d');
    this.canvasTemp = window.document.createElement('canvas');
    this.contextTemp = this.canvasTemp.getContext('2d');

    this.canvas.width =  this.canvasTemp.width = cb.width;
    this.canvas.height = this.canvasTemp.height = cb.height;

    // The render is upside down and back to front so we need to correct it
    this.context.scale(1,-1);
    this.context.translate(0,-this.canvas.height);
    this.context.globalCompositeOperation = "copy"; // if you have transparent pixels

    this.contextTemp.scale(1,-1);
    this.contextTemp.translate(0,-this.canvasTemp.height);    

    // Add a <a> to use to download an image file
    var linkElement = document.createElement('a');
    linkElement.id = 'link';
    window.document.body.appendChild(linkElement);
    this.entity.camera.camera.enabled = false;

	this.app.on('postrender', this.postRender, this);
    this.app.graphicsDevice.on('resizecanvas', this.resize, this);   
};

TakeScreens.prototype.resize = function() {
    // Render targets        
    var device = this.app.graphicsDevice;
    var width = Math.floor(device.width);
    var height = Math.floor(device.height);

    this.renderTarget.destroy();

    var colorBuffer = new pc.Texture(this.app.graphicsDevice, {
        width: 1024,
        height: 1024,
        format: pc.PIXELFORMAT_R8_G8_B8_B8,
        autoMipmap: true
    });
    var depthBuffer = new pc.Texture(this.app.graphicsDevice, {
        format: pc.PIXELFORMAT_DEPTHSTENCIL,
        width: 1024,
        height: 1024,
        mipmaps: false,
        addressU: pc.ADDRESS_CLAMP_TO_EDGE,
        addressV: pc.ADDRESS_CLAMP_TO_EDGE
    });
    colorBuffer.minFilter = pc.FILTER_LINEAR;
    colorBuffer.magFilter = pc.FILTER_LINEAR;

    this.renderTarget = new pc.RenderTarget({
        colorBuffer: colorBuffer,
        depthBuffer: depthBuffer,
        samples: 8 // Enable anti-alias 
    });

    this.entity.camera.renderTarget = this.renderTarget;    
};


//entry function
TakeScreens.prototype.getScreens = function( houseId, callback ) {
    this._camPasses = [
        {
            pass: ['miFilteredScreen']
        },
        {
            pass: ['miFilteredScreen']
        },
        {
            pass: ['miFilteredScreen']
        },
        {
            pass: ['miFilteredScreen']
        },
        {
            pass: ['miFilteredScreen','miFilteredWin0np','miFilteredGraphics']
        },
        {
            pass: ['miFilteredScreen','miFilteredWin1np','miFilteredGraphics']
        }
    ];

    this.resize();

    this.callback = callback;
    this.cameras = [];
    this.images = [];

    //I must to use asset registry instead scene hiearchy, becouse I don't have access to extras and camera property
    var items = this.app.assets.filter(function(asset) {
        return asset.type === 'model' && asset.name.indexOf( houseId ) !== -1;
    });
    var itemsScene = this.app.root.findByName( houseId ).children[0].model.model.graph;
    //copy extras property from asset resource
    itemsScene.children.forEach( ( child, index ) => {
        var item = items[0].resource.graph.children[index];
        //
        if ( item.hasOwnProperty('camera') )
            child.camera = item.camera;

        if ( item.hasOwnProperty('extras') )
            child.extras = item.extras;
        //I use rotation from asset original
        child.saveRot = item.getRotation();
        child.saveEulerRot = item.getLocalEulerAngles();
    });

    //get cameras
    this.setData( itemsScene );

    //sort by camera name
    this.cameras.sort(function(a, b) {
        return a.name.localeCompare(b.name);
    });

    this.houseId = houseId;
    this.element = document.querySelector( "#" + this.houseId );
    this._cameraPointIndex = 0;
    this.moveCameraToIndex(this._cameraPointIndex);
    this.entity.camera.camera.enabled = true;
};

TakeScreens.prototype.getScreen = async function( houseId, callback ) {
    this._camPasses = [
        {
            pass: ['miFilteredScreen']
        }
    ];    
    this.resize();    
    this.images = [];
    this.callback = callback;
    var mainCamera = this.app.root.findByName( "camera" );
    mainCamera.saveEulerRot = mainCamera.getLocalEulerAngles();
    this.cameras = [mainCamera];
    this.houseId = houseId;
    this.element = document.querySelector( "#" + this.houseId );
    this._cameraPointIndex = 0;
    this.moveCameraToIndex(this._cameraPointIndex);
    this.entity.camera.camera.enabled = true;    
};

//add contrast
TakeScreens.prototype.contrastImage = function(imageData, contrast) {

    var data = imageData.data;
    var factor = (259 * (contrast + 255)) / (255 * (259 - contrast));

    for(var i=0;i<data.length;i+=4)
    {
        data[i] = factor * (data[i] - 128) + 128;
        data[i+1] = factor * (data[i+1] - 128) + 128;
        data[i+2] = factor * (data[i+2] - 128) + 128;
    }
    return imageData;
};

//take current screen
TakeScreens.prototype.takeScreenshot = function ( index, isTemp ) {
    var colorBuffer = this.renderTarget.colorBuffer;

    var gl = this.app.graphicsDevice.gl;
    var fb = this.framebuffer;
    var pixels = this.pixels;
    gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, colorBuffer._glTexture, 0);
    gl.readPixels(0, 0, colorBuffer.width, colorBuffer.height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);

    // Get a pointer to the current location in the image.
    var palette = this.context.getImageData(0, 0, colorBuffer.width, colorBuffer.height); //x,y,w,h

    // Wrap your array as a Uint8ClampedArray
    palette.data.set(new Uint8ClampedArray(pixels)); // assuming values 0..255, RGBA, pre-mult.
    if ( index < 4 )
        palette = this.contrastImage(palette, 30);

    this.context.putImageData( palette, 0, 0 );
    this.context.drawImage( this.canvas, 0, 0 );
    //this.context.filter = "brightness(150%)";

    if ( isTemp || this._isTemp ) {
        this.contextTemp.drawImage( this.canvas, 0, 0 );    
    }

    if ( !isTemp && this._isTemp ) {
        this.context.drawImage( this.canvasTemp, 0, 0 );    
        this.contextTemp.clearRect(0, 0, this.canvasTemp.width, this.canvasTemp.height);
    }

    if ( !isTemp ) {
        var image = this.canvas.toDataURL('image/png');
        // Thanks https://stackoverflow.com/a/44487883
        //var link = document.getElementById('link');
        //var filename = 'Camera_' + index  + '.png';
        //link.setAttribute('download', filename );
        //link.setAttribute('href', this.canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
        //link.click();

        this.images.push( image );
    }
    //
    this._isTemp = isTemp;
};

TakeScreens.prototype.takeScreenshotPass = async function () {
    for (var i = 0; i < this._camPasses[this._cameraPointIndex].pass.length; ++i) {
        await this.app.root.findByName( this.houseId ).script.loadModel.addToScreenLayer( this._camPasses[this._cameraPointIndex].pass[i] );
        await this.takeScreenshot( this._cameraPointIndex, !( i === this._camPasses[this._cameraPointIndex].pass.length-1 )  );
    }    
    this._cameraPointIndex +=1;
    if ( this._cameraPointIndex >= this.cameras.length ) {
        this.end();
    } else {
        this.moveCameraToIndex(this._cameraPointIndex);
    }

};

TakeScreens.prototype.postRender = function () {
    if (this._triggerScreenshot) {
        this._triggerScreenshot = false;
        this.takeScreenshotPass();
    }
};

TakeScreens.prototype.end = function () {
    this.entity.camera.camera.enabled = false;

    //material restore
    this.setMaterial( this.app.root.findByName( this.houseId ).children[0].model.model.meshInstances, 'mStenaInterier', 0.8098, 0.8098, 0.85, true);
    this.setMaterial( this.app.root.findByName( this.houseId ).children[0].model.model.meshInstances, 'mWood', null, null, null, true);
    this.setMaterial( this.app.root.findByName( this.houseId ).children[0].model.model.meshInstances, 'mRoof', null, null, null, true);
    this.setMaterial( this.app.root.findByName( this.houseId ).children[0].model.model.meshInstances, 'mRoofClassic', null, null, null, true);
    this.app.root.findByName( this.houseId ).script.fadeByStyle.setFadeByState( 'all', () => {});

    //swap order of images when house is mirrored
    if ( Utils.hasClass( this.element, 'orientace-zrcadlova' ) ) {
        console.log('prohazuji');
        const swapPositions = (array, a ,b) => {
            [array[a], array[b]] = [array[b], array[a]];
        };
        swapPositions( this.images, 1, 3 );
    }
    //
    this.callback( this.images );
};

TakeScreens.prototype.moveCameraToIndex = function(index) {
    var transform = this.cameras[index];
    //
    this.entity.setPosition(transform.getPosition());
    //this.entity.setRotation( transform.saveRot ); by quaternion
    this.entity.setLocalEulerAngles( transform.saveEulerRot );
    //rotate skybox
    var r = new pc.Quat();
    r.setFromEulerAngles(0, ( 270 - (index*90 ) ) % 360 , 0);
    this.app.scene.skyboxRotation = r;

    //fixed rotation bug on cam 2 and 4 when house is mirrored
    //1 360,90,0
    //3 360, -90, 0
    if ( ( index === 1 || index === 3) && Utils.hasClass( this.element, 'orientace-zrcadlova' ) ) {
        this.entity.setLocalEulerAngles( 0, -transform.saveEulerRot.y ,0 ); //
    }

    //change ortoheight of camera by saved property, it is needed, typically if house has garage of car stand
    //uses scale property on cam in max!!
    if ( transform.hasOwnProperty('orthoHeight') ) {
        this.entity.camera.orthoHeight = transform.orthoHeight;
    }
    //end fixed
    if ( transform.hasOwnProperty('extras') ) {
        if ( transform.extras.hasOwnProperty('tags') ){
            if ( transform.extras.tags.includes("state-") ) {
                var state = transform.extras.tags.replace('state-', '');

                this.app.root.findByName( this.houseId ).script.fadeByStyle.setFadeByState( state, () => {
                    if ( index === 0 ) {
                        this.setMaterial( this.app.root.findByName( this.houseId ).children[0].model.model.meshInstances, 'mRoof', null, null, null, false);
                        this.setMaterial( this.app.root.findByName( this.houseId ).children[0].model.model.meshInstances, 'mRoofClassic', null, null, null, false);
                    }


                    if ( index === 4 ) {
                        var layer = this.app.scene.layers.getLayerByName( 'ScreenShotLayer' );
                
                        this.entity.script.ssao.enabled = false;
                        this.entity.camera.renderTarget = this.renderTarget;

                        this.entity.camera.enabled = false;
                        this.entity.camera.camera.layers = [ layer.id ];
                        this.entity.camera.enabled = true;

                        //zmena materialu sten a nasviceni na posledni dva snimky
                        this.setMaterial( this.app.root.findByName( this.houseId ).children[0].model.model.meshInstances, 'mStenaInterier', 0.2, 0.2, 0.2, false);
                        this.setMaterial( this.app.root.findByName( this.houseId ).children[0].model.model.meshInstances, 'mWood', null, null, null, false);
                        
                       //App.pc.setHDRIMap( 'Helipad.dds', ()=>{
                            this._triggerScreenshot = true;
                        //});
                    } else
                        this._triggerScreenshot = true;
                });
            }
        }
            
    } else {
        this._triggerScreenshot = true;
    }
};

//tools
//change material
TakeScreens.prototype.setMaterial = function( meshInstances, materialName, colorR, colorG, colorB, useMetalness ) {
    //
    meshInstances.forEach( ( mi ) => {
        if ( mi.material.name === materialName ){
            //
            if ( colorR != null && colorG != null && colorB != null )
                mi.material.diffuse = new pc.Color(colorR,colorG,colorB);
            //mi.material.shininess = shininess; //puvod 60
            mi.material.useMetalness = useMetalness;
            mi.material.update();
        }
    });
};
//get cameras
TakeScreens.prototype.setData = function( modelGraph ) {
    if (modelGraph === undefined )
        return;

    modelGraph.children.forEach( ( child ) => {
    	if ( child.hasOwnProperty('camera') ) {
    		this.cameras[child.camera] = child; 
    	}
        this.setData( child, true ); 
    });
};