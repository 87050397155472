var App = require('~/js/App');

module.exports = Backbone.Model.extend({
    defaults: {
        css: [],
        id: '',
        hash: '',
        owner: '',
        klient: '',
        datum: null,
        email: '',
        image: '',
        emaildata: {}
    },

    idAttribute: "hash",

    initialize: function () {
        this.on("invalid", function (model, error) {
            console.log("Houston, we have a problem: ", model,  error);
        });
    },

    constructor: function (attributes, options) {
        Backbone.Model.apply(this, arguments);
    },

    validate: function (attr) {
    	//
        //if (!attr.hash) {
        //   return "Invalid BookName supplied.";
        //}
    },

	urlRoot: App.API + '/planner'
});