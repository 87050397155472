var House3DView = require('~/js/pc/House3DView').default,
    ProjectModel = require('~/js/model/ProjectModel'),
    UserView = require('./nested/UserView'),
    ProjectView = require('./nested/house/ProjectView'),
    SaveView = require('./nested/house/SaveView'),
    FloorSetView = require('./nested/house/FloorSetView'),
    TitleView = require('./nested/house/TitleView'),
    App = require("~/js/App");

module.exports = Backbone.View.extend({
       
    className: 'house',

    initialize: function( id, hash ) {
        if ( hash ) {
            this.projectModel = new ProjectModel({ hash: hash }); 
            this.fetchProjectModel( id, hash );
        } else {
            this.projectModel = new ProjectModel();
        }
        //
        if ( !this.view3D )
            this.view3D = new House3DView( App.pc );        
        this.view3D.setModel( id );

        this.userView = new UserView();
        this.saveView = new SaveView( id, this.projectModel );
        this.projectView = new ProjectView( id );
        this.floorSetView = new FloorSetView();        
        this.titleView = new TitleView( id );  
        //    
        App.mainMenuView.update( id );
        //
        this.render();
    },

    render: function() {
        this.el.appendChild( this.titleView.el );           
        this.el.appendChild( this.floorSetView.el );  
        //        
        if ( !App.user.get("logged_in") ) return;
        this.el.appendChild( this.userView.el );           
        this.el.appendChild( this.saveView.el );
        this.el.appendChild( this.projectView.el );
        //
        return this;
    },

    update: function( id, hash ) {
        if ( hash ) {
            this.projectModel = new ProjectModel({ hash: hash });             
            this.fetchProjectModel( id, hash );
        } else {
            this.projectModel.clear();
            this.projectModel = new ProjectModel();
        }        
        this.titleView.update( id );
        this.floorSetView.update();  
        this.saveView.update( id, this.projectModel );
        this.projectView.update( id );
        //
        App.mainMenuView.update( id );
        //
        this.view3D.setModel( id );        
    },

    fetchProjectModel: function( id, hash ) {
        this.projectModel.fetch({
            success:  ( res ) => {
                console.log("setting ");
                App.mainMenuView.setCSS( id, res.get("css") );
            },
            error: (model, xhr, options) => {console.log("chyba");
            }
        });
    },

    dispose: function() {
        this.userView.dispose();
        this.titleView.dispose();
        this.floorSetView.dispose();
        this.saveView.dispose();
        this.projectView.dispose();
        //
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }
});