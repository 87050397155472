var InfoView = require("./InfoView"),
	template = require('~/js/template/layout/loginTemplate.html'),
    Utils = require('~/js/Utils');

module.exports = Backbone.View.extend({
       
    tagName: 'section',

	id: 'login',

    events: {
        'click #login-btn'                  : 'onClickLogin',
        'focusin input'                     : 'onFocusIn',
        'focusout input'                    : 'onFocusOut'
    },   

    initialize: function() {
        this.fields = {};    	
    	this.infoView = new InfoView();
        this.render();
    },

    render: function() {
        console.log( App.user );    	
        this.el.appendChild( this.infoView.el );            
        this.el.innerHTML += template();   
        return this;
    },

    onFocusIn: function( evt ) {
        Utils.addClass( document.body, 'focus-in' );
    },

    onFocusOut: function( evt ) {
        Utils.removeClass( document.body, 'focus-in' );
    },    

    onClickLogin: function ( evt ) {
        evt.preventDefault();        
        //verify data
        var error;        
        this.infoView.clear();
        //
        this.el.querySelectorAll('input').forEach( (el, i) => {
            var val = el.value;
            el.style.borderColor = '';
            //
            if ( el.getAttribute('required') !== null ) {
                if ( el.getAttribute('regex') !== null ) {
                    var patt = new RegExp( el.getAttribute('regex') );
                    if ( !patt.test( val ) )
                        error = this.setError( el, el.getAttribute('color'), el.getAttribute('info'), i/10 );
                } else if ( val === "" ) {
                    error = this.setError( el, el.getAttribute('color'), 'Položka&nbsp;<strong>' + el.getAttribute('placeholder')  + '</strong>&nbsp;není vyplněna.', i/10 );
                }
            }
            if ( el.getAttribute('dtb') === '' )
                this.fields[ el.name ] = val;
        });
        if ( !error ) {
            Utils.addClass( evt.currentTarget, 'progress-start' );
            setTimeout(()=> { Utils.addClass( evt.currentTarget, 'progress' ); }, 125);   
            //
			App.user.set("username", this.fields.username );
			App.user.set("password", this.fields.password );
            //
            App.user.save({}, {
                success: (model, response, options) => {
                    console.log( response );
                	if('error' in response ) {
                        Utils.removeClass( evt.currentTarget, 'progress-start' );
                        Utils.removeClass( evt.currentTarget, 'progress' );
                        this.infoView.setInfo( '#ico-info1', "#ff0000", response.error, 0 );
                	} else {
                		App.user.set("logged_in", true );
                        window.location.reload();
                	}

                },
                error: (model, xhr, options) => {
                    Utils.removeClass( evt.currentTarget, 'progress-start' );
                    Utils.removeClass( evt.currentTarget, 'progress' );
                    this.infoView.setInfo( '#ico-info1', "#ff0000", "Chyba v komunikaci se serverem.", 0 );
                }
            });
        };
    },

    setError: function ( el, color, info, delay ){
        el.style.borderColor = color;
        this.infoView.setInfo( '#ico-info1', color, info, delay );
        return true;
    },

    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }
});