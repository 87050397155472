var App = require('~/js/App');

module.exports = Backbone.Model.extend({

    defaults: {
        id: '',
        username: '',
        password: '',
        email: '',
        logged_in: false
    },

    idAttribute: "username",    

    urlRoot: App.API + '/planneruser/auth',

    checkAuth: function(callback, args) {
        this.fetch({ 
            success: (mod, res) => {
                if(!res.error ){
                    this.set({ logged_in : true });
                    if('success' in callback) callback.success(mod, res);    
                } else {
                    this.set({ logged_in : false });
                    if('error' in callback) callback.error(mod, res);    
                }
            },
            error: (mod, res) => {
                this.set({ logged_in : false });
                if('error' in callback) callback.error(mod, res);    
            }
        }).done( function(){
            if('complete' in callback) callback.complete();
        });
    },

    logout: function() {
        App.user.set("username", "@@logout" );
        //
        App.user.save({}, {
            success: (model, response, options) => {
                if('error' in response ) {
                    console.log( "logout error" );
                } else {
                    window.location.reload();
                }

            },
            error: (model, xhr, options) => {
                console.log("error");
            }
        });
    }
});
