import protoScript from "~/js/lib/ProtoScript";
import App from '~/js/App';
import Utils from '~/js/Utils';

var MirrorByStyle = function() {
    this.name = 'mirrorByStyle';
    //
    this.attributes = {
        'element': { type: "string", default:"" }
    };

	MirrorByStyle.prototype.initialize = function() {
	    this.element = document.querySelector( "#" + this.element );
	    //add listeners
    	this.app.on('form:change', this.onAttributeChange, this );  
	};
	//handlers
	MirrorByStyle.prototype.onAttributeChange = function( time, callback ) {
		if ( Utils.hasClass( this.element, 'orientace-zakladni' ) === true ) {
			this.entity.setLocalScale( 1, 1, 1 );
		} else {
			this.entity.setLocalScale( -1, 1, 1 );
		}
	};

};
export default protoScript( MirrorByStyle );
//