module.exports = Backbone.View.extend({
       
      tagName: 'ul',

     	className: 'info',

      initialize: function() { 
        this.el.id = this.cid;
      },

      render: function() {
        return this;
      },

      setInfo: function( icon, color, message, delay ) {
          document.querySelector('#'+this.cid).innerHTML += "<li style='animation-delay: " + delay + "s; background: " + color + "'>" + "<svg><use xlink:href='" + icon + "'></use></svg>" + message + '</li>';

      },

      clear: function( message ) {   
          document.querySelector('#'+this.cid).innerHTML = '';
      }

});