import App from '~/js/App';
import Utils from '~/js/Utils';

var LoadModel = require('./pc.script/LoadModel').default,
	MirrorByStyle = require('./pc.script/MirrorByStyle').default,
	FadeByStyle = require('./pc.script/FadeByStyle').default,
	MorphByStyle = require('./pc.script/MorphByStyle').default,
	TransByStyle = require('./pc.script/TransByStyle').default,
	TintByStyle = require('./pc.script/TintByStyle').default;	

let instance = null;
let root = null;
let modelInstance = null;

class House3DView {

	constructor ( playcanvas ) {
		this.name = 'House3DView';
		//
		this.app = playcanvas.app;
		//
	    if ( !instance ){
			instance = this;
		    //create holder form models, never dispose it
			root = new pc.Entity('models');
			this.app.root.addChild( root );	
	    } 
	}

	setModel( id ) {
		if ( modelInstance ) {
			modelInstance.enabled = false;
			modelInstance.script.loadModel.layerClear();
		}
		modelInstance = root.findByName( id );
		//
		if ( modelInstance ) {
			modelInstance.enabled = true;
			modelInstance.script.loadModel.layerAdd();
		} else {
			modelInstance = new pc.Entity();
			modelInstance.name = id;
			modelInstance.addComponent('script');
			var loadModel = new LoadModel( this.app );
			var mirrorByStyle = new MirrorByStyle( this.app );			
			var fadeByStyle = new FadeByStyle( this.app );
			var morphByStyle = new MorphByStyle( this.app );
			var transByStyle = new TransByStyle( this.app );
			var tintByStyle = new TintByStyle( this.app );
			//			
			modelInstance.script.create( loadModel.name, { attributes: { scale: 1 }});
			modelInstance.script.create( mirrorByStyle.name, { attributes: { element: id }});			
			modelInstance.script.create( fadeByStyle.name, { attributes: { element: id }});
			modelInstance.script.create( morphByStyle.name, { attributes: { element: id }});
			modelInstance.script.create( transByStyle.name, { attributes: { element: id }});
			modelInstance.script.create( tintByStyle.name, { attributes: { element: id }});
			//modelInstance.setPosition( 0,0,0 );
			//modelInstance.setLocalEulerAngles( 0,0,0 );
			//modelInstance.setLocalScale( 1 );
			//
			root.addChild( modelInstance );
			//
			modelInstance.script.loadModel.load( id );
		}
	}


	dispose() {}
  };

export default House3DView;