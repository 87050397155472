module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p class="test">'+
((__t=( App.user.get('username') ))==null?'':__t)+
'</p>\r\n<p>'+
((__t=( App.user.get('email') ))==null?'':__t)+
'</p>\r\n<a href="#" id="logout">odhlásit</a>';
}
return __p;
};
