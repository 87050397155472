var App = require("~/js/App"),
	Utils = require('~/js/Utils');

var KeyboardInput = require('./pc.script/vendors/KeyboardInput').default;
var MouseInput = require('./pc.script/vendors/MouseInput').default;
var TouchInput = require('./pc.script/vendors/TouchInput').default;
var OrbitCamera = require('./pc.script/vendors/OrbitCamera').default;
var TakeScreens = require('./pc.script/TakeScreens').default;

import Quality from './pc.script/Quality';
import SAO from './pc.script/posteffect-sao';
import SSAO from './pc.script/posteffect-ssao';

var Tween = require('./pc.script/vendors/Tween').default;

var vs = require('./shaders/hdriChunkVS.glsl').default,
	fs = require('./shaders/hdriChunkFS.glsl').default;

import { DebugPC } from './DebugPC';

var Playcanvas = function( readyCall ){
    this.name = 'playcanvas';
    this.readyCall = readyCall;
    //
    this.canvas = document.getElementById("application-canvas");
	this.app = new pc.Application( this.canvas, {
		mouse: new pc.Mouse( document.body ),
		keyboard: new pc.Keyboard(window),
		touch: new pc.TouchDevice(document.body),
		elementInput: new pc.ElementInput( this.canvas )
	});
	
	//if (pc.app.graphicsDevice.webgl2 === false) // do something
	
	var debugPC = new DebugPC( this.app );

   	this.setupWASM();
};

//*************************************************HACK PC -> add tags(extras) and bumpines hack
//30685 const createNode = function createNode(gltfNode, nodeIndex) {
    //add camera property.basisInitialize
    //if (gltfNode.hasOwnProperty("camera")) {
    //    entity.camera = gltfNode.camera;
    //}
    //add extrass propertis to graphnode
    //if (gltfNode.hasOwnProperty('extras')) {
    //    entity.extras = gltfNode.extras;
    //}

//const createMaterial = function createMaterial(gltfMaterial, textures, disableFlipV) {
//30451 	if (gltfMaterial.hasOwnProperty('normalTexture')) {
		//...
		//
		//material.bumpiness = gltfMaterial.pbrMetallicRoughness.metallicFactor; //my customs

Playcanvas.prototype = {

	    setupWASM: function () {
			Utils.loadScript( App.ASSETS + 'js/wasm-loader.js' ).then(() => {
				

				pc.basisInitialize({
				    glueUrl: App.ASSETS + 'js/basis.wasm.js',
				    wasmUrl: App.ASSETS + 'js/basis.wasm.wasm',
				    fallbackUrl: App.ASSETS + 'js/basis.js'
				});					

				if ( wasmSupported() ) {
						loadWasmModuleAsync('DracoDecoderModule', App.ASSETS + 'js/draco.wasm.js', App.ASSETS + 'js/draco.wasm.wasm', () => {
								this.setup3D();
							});
						} else {
							loadWasmModuleAsync('DracoDecoderModule', App.ASSETS + 'js/draco.js', '',  () => {
								this.setup3D();
							});
						}
	
	           });
	    },

	    setup3D: function () {
	    	//setup tweener
			var tween = new Tween();
			//
	        this.resize();
	        window.addEventListener('resize', this.resize.bind(this), false);
	        window.addEventListener('orientationchange', this.resize.bind(this), false);

		    // use device pixel ratio
		    this.app.graphicsDevice.maxPixelRatio = window.devicePixelRatio; //most important for mobile retina aliasing quality
	        this.app.start();        
	        this.app.setCanvasFillMode( pc.FILLMODE_FILL_WINDOW );
	        this.app.setCanvasResolution( pc.RESOLUTION_AUTO );

	        this.app.root.name = 'RootNode';
	        
			//this.app.mouse.disableContextMenu();

	        this.setHDRIMap( 'Helipad.dds', ()=>{});

	        var mouseInput = new MouseInput( this.app );
	        var touchInput = new TouchInput( this.app );        
	        var orbitCamera = new OrbitCamera( this.app );         

	        this.camera = new pc.Entity('camera');
	        this.camera.name = "camera";
	        this.camera.addComponent("camera", {
	            fov: 45,
	            clearColor: new pc.Color(0,0,0,0)
	            //projection: pc.PROJECTION_ORTHOGRAPHIC,
			});
	        this.camera.addComponent('script');

	        //----------------------------------------
	        // Create a new layer to put in front of everything
	        var layer = new pc.Layer({ name: "ScreenShotLayer" });
	        var skyLayer = new pc.Layer({ name: "SkyLayer" });

	        // get the world layer index
	        var worldLayer = this.app.scene.layers.getLayerById( pc.LAYERID_WORLD );

	        var uiLayer = this.app.scene.layers.getLayerById( pc.LAYERID_UI );
	        var idx = this.app.scene.layers.getTransparentIndex(worldLayer);
	        // insert the new layers after the world layer
	        this.app.scene.layers.insert(layer,0); //idx-1
	        this.app.scene.layers.push( skyLayer );
	        console.log( this.app.scene.layers );

	        // Create an Entity with a point light component and a sphere model component.
	        this.light = new pc.Entity();
	        this.light.name = "light";
	        this.light.addComponent("light", {
	            type: "directional",
	            color: new pc.Color(1, 1, 1),
	            castShadows: true,
	            intensity: 1,
	            shadowBias: 0.2,
	            shadowDistance: 35,
	            normalOffsetBias: 0.05,
	            shadowResolution: 2048,
            	shadowUpdateMode: pc.SHADOWUPDATE_REALTIME, //pc.SHADOWUPDATE_THISFRAME
            	shadowType: pc.SHADOW_PCF3,
            	layers: [ worldLayer.id, layer.id ]
	        });

	        // Create second camera for taking a pictures
	        this.cameraRender = new pc.Entity();
	        this.cameraRender.name = "cameraRender";	        
	        this.cameraRender.addComponent("camera", {
	            fov: 35,
	            clearColor: new pc.Color(1, 1, 1, 0.0),
	            projection: pc.PROJECTION_ORTHOGRAPHIC,
	            orthoHeight: 8
	        });

	        this.app.root.addChild( this.cameraRender );	        
	        this.cameraRender.addComponent('script');	        
	      	var takeScreens = new TakeScreens( this.app );	
	        this.cameraRender.script.create( takeScreens.name );	     
	        //
	        this.cameraRender.setPosition(0, 4.66, 7.22); 	
	        this.cameraRender.setLocalEulerAngles( 0, 0, 0);
	        //
			pc.registerScript(SAO,'sao');	        
			this.camera.script.create( 'sao', {
                attributes: {
				    saoResolutionScale: 1,
					saoSamples: 11,
					saoIntensity: 1.0, //0.71
					saoScale: ( Utils.isMobile() ? 0.5 : 1.0 ),
					saoBias: 0.01,
					saoKernelRadius: 75,
					saoBlurRadius: ( Utils.isMobile() ? 0.5 : 0.75 ),
					saoBlurEnabled: true,					
					saoDebug: false
                }
            });

			pc.registerScript(SSAO,'ssao');	
			this.cameraRender.script.create( 'ssao', {
                attributes: {

                }
            });

	        //remove skykox layer
			//this.layers = this.camera.camera.layers;
	        //var skyboxLayer = this.app.scene.layers.getLayerById( pc.LAYERID_SKYBOX );
	        //var ido = this.app.scene.layers.getOpaqueIndex( skyboxLayer );			
			//this.layers.splice( this.layers.indexOf( ido ), 1);
			//console.log( skyboxLayer );
			//console.log( this.app.scene.layers, this.camera.camera.layers );

			//
	        // Make the camera orbit
	        this.camera.script.create( orbitCamera.name );
	        this.camera.script.create( mouseInput.name );
	        this.camera.script.create( touchInput.name );
	
	        // Add the new Entities to the hierarchy
	        this.app.root.addChild( this.light );
	        this.light.setLocalEulerAngles(-35, -35, 0);		        
	        this.app.root.addChild( this.camera );	

	        //grass plane
			var plane = new pc.Entity();
			plane.addComponent("model", { type: "plane",  });
			plane.setLocalScale(120, 1, 120);
			this.app.root.addChild( plane );
			plane.setPosition(0, -0.01, 0);       //-0.0001

            this.app.assets.loadFromUrl( App.ASSETS + 'maps/grass1de-50_lightness512.jpg', "texture", (err, asset) => {
            	var diffuseMap = asset.resource;
                this.app.assets.loadFromUrl( App.ASSETS + 'maps/Spark_Glow.png', "texture", (err, asset) => {
					var material = new pc.StandardMaterial();
					material.diffuseMap = diffuseMap;
					material.diffuseMapTiling = new pc.Vec2(20,20);
					material.opacityMap = asset.resource;
					material.blendType = pc.BLEND_NORMAL;
					material.alphaToCoverage = false;
					//material.alphaTest = 0;
					material.depthWrite = false;
					material.opacityMapChannel = "r";
					//material.opacity = 0.709;
					//material.useMetalness = true;
					material.update();
					plane.model.meshInstances[0].material = material;
                });
			}); 

		    this.app.assets.loadFromUrl( App.ASSETS + 'models/sphere.json', "model", (err, asset) => {
				var entity = new pc.Entity();
				entity.name = "sphere";
		        entity.addComponent("model", {
		        	castShadows: false,
		        	receiveShadows: false,
		        	//layers: [ uiLayer.id ] //,
		        });
		        entity.model.model = asset.resource;
		        //entity.model.layers = [uiLayer.id];
			    //uiLayer.addMeshInstances( entity.model.model.meshInstances );
			    //camera.camera.disablePostEffectsLayer = camera.camera.disablePostEffectsLayer === pc.LAYERID_UI ? undefined : pc.LAYERID_UI;
	               
		        entity.setLocalPosition(0, 0, 0);
		        entity.setLocalScale(300, 600,300);
		        this.app.root.addChild(entity);

		        var fakeCubemapAsset = new pc.Asset('helipad', 'cubemap', null, {
		            "textures": [
		                /*App.ASSETS + 'maps/cube2/P7D1M7_4096x2048_r.jpg',
		                App.ASSETS + 'maps/cube2/P7D1M7_4096x2048_l.jpg',
		                App.ASSETS + 'maps/cube2/P7D1M7_4096x2048_u.jpg',
		                App.ASSETS + 'maps/cube2/P7D1M7_4096x2048_d.jpg',
		                App.ASSETS + 'maps/cube2/P7D1M7_4096x2048_f.jpg',
		                App.ASSETS + 'maps/cube2/P7D1M7_4096x2048_b.jpg', */
						App.ASSETS + 'maps/cube1/Custom_r.png',
		                App.ASSETS + 'maps/cube1/Custom_l.png',
		                App.ASSETS + 'maps/cube1/Custom_u.png',
		                App.ASSETS + 'maps/cube1/Custom_d.png',
		                App.ASSETS + 'maps/cube1/Custom_f.png',
		                App.ASSETS + 'maps/cube1/Custom_b.png',
		            ]
		        });

		        this.app.assets.add( fakeCubemapAsset );
		        this.app.assets.load( fakeCubemapAsset );
		        fakeCubemapAsset.ready( () => {
		        	var material = entity.model.model.meshInstances[0].material;

			        material.chunks.startVS = vs;
			        material.chunks.diffusePS = fs;

			        material.setParameter('_SphereRadius', 66);
			        material.setParameter('_ProjectorY', 3.92);
			        material.setParameter('_DirectionRotation', 144.3 * (Math.PI / 180) );    
			        material.setParameter('_TextureCubeMap', fakeCubemapAsset.resource);
			        
			        material.cull = pc.CULLFACE_FRONT;

			        //disable sao
			        //material.depthWrite = false;
			        material.update();
		        });
		    }); 
		    //auto quality
		    pc.registerScript( Quality,'quality');	
		    this.camera.script.create( 'quality' );	

	        console.timeEnd('App');
            this.readyCall();

            if ( Utils.isIos() )
				alert("Aplikace Canaba Planner není zatím odladěna na systém MacOS.");

            if ( Utils.isMobile() )
				alert("Aplikace Canaba Planner není určena pro mobilní zařízení.");			

            if (pc.app.graphicsDevice.webgl2 === false)
				alert("Aplikace Canaba Planner potřebuje ke svému chodu lepší počítač nebo grafickou kartu.");					

	    },

	onKeyDown: function( event ) {

	},	    
		takeScreen: function( houseId, callback ) {
			this.cameraRender.camera.projection = pc.PROJECTION_PERSPECTIVE;
			//
	    	this.cameraRender.script.takeScreens.getScreen( houseId, ( image ) => { 
	    		this.cameraRender.camera.projection = pc.PROJECTION_ORTHOGRAPHIC;
	    		callback( image );
	    	});
		},

	    takeScreens: function( houseId, callback ) {
	    	var saveQuality = Number( this.camera.script.quality.quality );
	    	var saveLayers = this.cameraRender.camera.layers;
	    	var sphere = this.app.root.findByName( "sphere" );
	    	//
	    	if ( saveQuality !== 4 )
	    		this.camera.script.quality.quality = 4;
	    	//
	    	this.light.setLocalEulerAngles(0, 0, 0);
	    	this.light.light.shadowType = pc.SHADOW_VSM16;
	    	this.light.light.shadowUpdateMode = pc.SHADOWUPDATE_REALTIME;

	    	this.light.light.vsmBlurMode = pc.BLUR_BOX;
	    	this.light.light.vsmBlurSize = 20;
	    	this.light.light.vsmBias = 0.0025;
	    	//console.log(    this.light.light.vsmBlurSize, this.light.light.vsmBlurMode, this.light.light.vsmBias );

	    	// disable fake skybox and skybox layer
	    	sphere.enabled = false;
	    	this.app.scene.layers.getLayerById(2).enabled = false;

	    	this.cameraRender.script.takeScreens.getScreens( houseId, ( images ) => { 
	    		//
	    		this.light.setLocalEulerAngles(-35, -35, 0);
	    		this.light.light.shadowType = pc.SHADOW_PCF3;
				this.light.light.vsmBlurMode = pc.BLUR_GAUSSIAN;	    		
	    		this.light.light.shadowUpdateMode = pc.SHADOWUPDATE_THISFRAME;
	    		//restore quality
	    		this.camera.script.quality.quality = saveQuality;
	    		//restore post effects
	    		var worldLayer = this.app.scene.layers.getLayerById( pc.LAYERID_WORLD );	    		
				this.cameraRender.enabled = false;
				this.cameraRender.camera.layers = saveLayers;
				this.cameraRender.enabled = true;
				this.cameraRender.script.ssao.enabled = true;	    		
				// enable fake skybox and skybox layer
				sphere.enabled = true;
				this.app.scene.layers.getLayerById(2).enabled = true;
	    		//
	    		callback( images );
	    	});
	    },

	    // methods for scene
	    resize: function () {
	        var size = this.app.resizeCanvas( this.canvas.width, this.canvas.height);
	        //this.canvas.style.width = '';
	        //this.canvas.style.height = '';

	    },

	    updateShadow: function() {
	    	//this.light.shadowUpdateMode = pc.SHADOWUPDATE_REALTIME;
	    	//this.light.shadowUpdateMode = pc.SHADOWUPDATE_THISFRAME;

		    this.light.light.shadowUpdateMode = pc.SHADOWUPDATE_REALTIME; //realtime   
		    //
		    //setTimeout(() => { this.light.light.shadowUpdateMode = pc.SHADOWUPDATE_THISFRAME ; }, 100); //little delay for scene is drawn    
	    },

	    setHDRIMap: function( hdri, callback ) {
    	    this.app.scene.gammaCorrection = pc.GAMMA_SRGB; //GAMMA_NONE
	        this.app.scene.toneMapping = pc.TONEMAP_ACES;   
	        this.app.scene.ambientLight = new pc.Color(0.1, 0.1, 0.1);
	        //
	    	var assets = this.app.assets.findAll(hdri, "cubemap");
	    	if ( assets.length ) {
	    		//cubemap has been already loaded
	            this.app.scene.skyboxMip = 4;             
	            this.app.scene.setSkybox(assets[0].resources);
	            this.app.scene.skyboxIntensity = 1;

	            callback();
	    	} else {
		        var cubemapAsset = new pc.Asset(hdri, 'cubemap', {
		            url: App.ASSETS + 'hdri/' + hdri
		        }, {
		            "magFilter": 1,
		            "minFilter": 5,
		            "anisotropy": 1,
		            "name": hdri,
		            "rgbm": true,
		            "prefiltered": hdri
		        });
		        this.app.assets.add( cubemapAsset );
		        this.app.assets.load( cubemapAsset );

		        cubemapAsset.ready( () => {

		            this.app.scene.skyboxMip = 4;             
		            this.app.scene.setSkybox(cubemapAsset.resources);
		            this.app.scene.skyboxIntensity = 1;
		            callback();
		        });
	    	}
	    }

};

export default Playcanvas;