var BasicController = require('./BasicController'),
	HouseView = require('../view/content/HouseView'),
	LoginView = require('../view/content/LoginView'),
	App = require("~/js/App");

module.exports = BasicController.extend({
	routes: {
		[App.HOME_DIR + ':id'] :'setHouse',
		[App.HOME_DIR + ':id/:hash'] :'setHouse'
	},

	initialize: function() {

	},

	setHouse: function( id, hash) {
		this.view = App.user.get("logged_in") || ( !App.user.get("logged_in") && hash ) ? new HouseView( id, hash ) : new LoginView();
		document.body.appendChild( this.view.el );  
	},

	setHouseUpdate: function( id, hash ) {
		this.view.update( id, hash );		
	},

	remove: function() {
		this.view.dispose();
	}
});