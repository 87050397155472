import protoScript from "~/js/lib/ProtoScript";
import Utils from '~/js/Utils';

var TintByStyle = function(){
    this.name = 'tintByStyle';
    //
    this.attributes = {
        'element': { type: "string", default:"" }
    };

	TintByStyle.prototype.initialize = function() {
	    this.element = document.querySelector( "#" + this.element );
	    //add listeners
	    this.observer = new MutationObserver( this.onMutationObserve.bind(this) );
	    this.observer.observe( this.element, { attributes: true });	  	
	};

	//handlers
	TintByStyle.prototype.onMutationObserve = function( mutations ) {
		mutations.forEach((mutation) => {
			if ( mutation.type == "attributes" )
				this.onAttributeChange(mutation, 0.5);
		});
	};
	TintByStyle.prototype.onAttributeChange = function( mutation, time ) {
		var styleArray = mutation.target.className.split(' ');
		var style = styleArray[ styleArray.length-1 ];
		var styleValue = style.split('-')[1];

		console.log("zmena atributu", style);
		//
		if ( Utils.isHex( styleValue ) ) {
			var materialName = style.split('-')[0];
			var mi = this.entity.findByName("gltf").model.model.meshInstances.filter( item => {return item._material.name.includes( materialName ); }); //array
			var materials = [].concat(...mi.map(({material}) => material));
			//
			this.tweenTint( materials, styleValue, time );
		}
	};

	TintByStyle.prototype.setDefaultTint = function( model ) {
	    //add default tint by style
	    this.element.className.split(' ').forEach( ( style, i ) => {
	    	var styleValue = style.split('-')[1];
			if ( Utils.isHex( styleValue ) ) {
				var materialName = style.split('-')[0];
				var mi = model.meshInstances.filter( item => {return item._material.name.includes( materialName ); }); //array
				var materials = [].concat(...mi.map(({material}) => material));
				//
				this.tweenTint( materials, styleValue, 0.001 );
			}
	    });
	};

	//tweening
	TintByStyle.prototype.tweenTint = function( materials, value, time ) {
		//
		var col = new pc.Color().fromString( '#' + value );

		materials.forEach( ( mat, i ) => {
			var dataFrom = { r: mat.diffuse.r, g: mat.diffuse.g, b: mat.diffuse.b };
			this.entity
			.tween( dataFrom )
			.to( { r: col.r, g: col.g, b: col.b } , time, pc.QuadraticInOut )
			.on('update', function () {
				mat.diffuse = new pc.Color( dataFrom.r, dataFrom.g, dataFrom.b );
				mat.update();
			})
			.on('complete', function () { })
			.start();
		});
	};

};
export default protoScript( TintByStyle );
//