var ContentView = require('./layout/ContentView'),
    PopupView = require('./layout/PopupView'),
    MainMenuView = require('./layout/MainMenuView'),
    App = require("~/js/App");

module.exports = Backbone.View.extend({ 

    initialize: function () {
    },

    render: function () {

        App.mainMenuView = new MainMenuView();
        document.body.appendChild( App.mainMenuView.el );

        this.contentView = new ContentView();
        document.body.appendChild( this.contentView.el );

        App.popUp = new PopupView();
        document.body.appendChild( App.popUp.el );        

        return this;
    },

    update: function( obj ) {},
 
    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }

});
