var BasicController = require('./BasicController'),
	HomeView = require('../view/content/HomeView'),
	LoginView = require('../view/content/LoginView'),
 	App = require("~/js/App");

module.exports = BasicController.extend({
	routes: {
        [App.HOME_DIR] : 'setHome',
	},

	initialize: function() {

	},

	setHome: function() {
		this.view = App.user.get("logged_in") ? new HomeView() : new LoginView();
		document.body.querySelector('main').prepend( this.view.el );
	},

	setHomeUpdate: function( id, hash ) {
		console.log("update");
		this.view = App.user.get("logged_in") ? new HomeView() : new LoginView();	
	},	

	remove: function() {
		this.view.dispose();
	}
});